﻿@import "../common/variables";

.Alternative-error {

	.InputGroup .error,
	select.error {
		border: 1px solid #f04124 !important;
	}

	select.error {
		margin-bottom: 1rem;
	}

	button[disabled] {
		background-color: #e62245 !important;
	}
}