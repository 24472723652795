﻿@import "../common/variables";

@Birthday-background :  #32A845;
.Birthday{
    background: @Birthday-background;
    padding:20px 0;
    color:#fff;
    position:relative;
    width:100%;
    .large-6{
        margin:0 0 5px 0;
        text-align:center;
    }
}

.Birthday-title{
    text-align:center;
    padding-top:65px;
    margin-bottom:20px;
    font-size:11px;
    &:before{
        font-family: FontAwesome;
        content:"\f1fd";
        font-size:50px;
        position:absolute;
        top:20px;
        margin-left:auto;
        margin-right:auto;
        left:0;
        right:0;
        text-align:center;
    }
}
.Birthday-noBirthday{
    text-align:center;
}
@media (min-width: 641px){
    .Birthday .large-6{
        text-align:left;
    }
    .Birthday-title{
        margin-bottom:0px;
    }
    .Birthday-noBirthday{
        margin-top:40px!important;
        
    }
}
/*@media (min-width: 64.063em){
    .Birthday .large-6{
        text-align:left;
    }
    .Birthday-title{
        margin-bottom:0px;
    }
}*/