﻿/**
 * Contain floats
 *
 * Make an element expand to contain floated children.
 * Uses pseudo-elements (micro clearfix).
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of the
 *    element.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.u-cf:before,
.u-cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.u-cf:after {
    clear: both;
}

/**
 * New block formatting context
 *
 * This affords some useful properties to the element. It won't wrap under
 * floats. Will also contain any floated children.

 * N.B. This will clip overflow. Use the alternative method below if this is
 * problematic.
 */

.u-nbfc {
    overflow: hidden !important;
}

/**
 * Floats
 */

.u-pullLeft {
    float: left !important;
}

.u-pullRight {
    float: right !important;
}

.u-clear {
    clear: both;
}
.u-lg-pullRight {
    @media (min-width:1025px){
        float: right !important;
    }
    
}