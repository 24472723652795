﻿.ImagePosition-center {
    background-position: center !important;
}

.ImagePosition-centerTop {
    background-position: center top !important;
}

.ImagePosition-centerBottom {
    background-position: center bottom !important;
}


.ImagePosition-flexStart {
    align-items: flex-start !important;
}

.ImagePosition-flexEnd {
    align-items: flex-end !important;
}

.ImagePosition-flexCenter {
    align-items: center !important;
}


// future responsive settings
/*.ImagePosition-sm-Center {
    @media screen and (max-width: 767px) {
        background-position: center;
    }
}

.ImagePosition-sm-centerTop {
    @media screen and (max-width: 767px) {
        background-position: center top;
    }
}

.ImagePosition-sm-centerBottom {
    @media screen and (max-width: 767px) {
        background-position: center bottom;
    }
}



.ImagePosition-md-center {
    @media (min-width: 768px) {
        background-position: center;
    }
}

.ImagePosition-md-centerTop {
    @media (min-width: 768px) {
        background-position: center top;
    }
}

.ImagePosition-md-centerBottom {
    @media (min-width: 768px) {
   
        background-position: center bottom;
    }
}



.ImagePosition-lg-center {
    @media (min-width: 1023px) {
        background-position: center;
    }
}

.ImagePosition-lg-centerTop {
    @media (min-width: 1023px) {
        background-position: center top;
    }
}

.ImagePosition-lg-centerBottom {
    @media (min-width: 1023px) {
        background-position: center bottom;
    }
}*/



