﻿@import "../common/variables";

@OnBoarding-background: #32A845;

.OnBoarding {
    margin-bottom: 1em;
    padding: 2em 10em;
    background: #fff;
    border-radius: 6px;
    //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.10);
    border: 1px solid #d8d8d8;
    display: block;
    width: 100%;
    text-align: center;
    position: relative;

    p {
        font-size: 1.2em;
    }

    small {
        font-size: 1em;
    }

    &-close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: #D8173C;
        background-color: #efefef;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        font-size: 1.4em;
        display: table;
    }

    &-content {
        margin-top: 2em !important;
    }

    &-header {
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 1em;

        p {
            padding: 0 1em;
        }
    }

    &-links {
        margin-top: 2em !important;
        display: flex;
        justify-content: space-around;

        .button {
            background-color: #23313F;
        }
    }

    &.is-collapsed {
        height: 0px;
        transition: height 0.3s ease-out;
    }
}
