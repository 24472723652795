﻿/**BANNER**/

.CookieConsent {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #333;
    color: #fff;
    display: none;

    &.is-active{
        display: block;
    }

    &-dialog {
        padding: 1rem;
        align-items: center;
        justify-content: space-between;

        @media screen and (min-width: 768px) {
            display: flex;
        }

        Button.CookieConsent-dismiss {
            margin-left: 10px;
        }

        nav {
            display: flex;
            justify-content: flex-end;
            margin-top: 1rem;

            @media screen and (min-width: 768px) {
                margin-top: 0;
            }
        }
    }

    &-content {
        font-size: 14px;
    }
}

/*MODAL*/
.Modal.js-cookieModal {


    .Modal-dialog {
        border-radius: 6px;
        padding: 0px;
    }

    .Modal-header {
        border-bottom: 1px solid rgba(220, 220, 220, 0.4);
        padding: 15px 0px 0px 15px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .Heading {
            font-size: 18px;
            color: rgba(0,0,0,0.8);
            font-weight: 600;
            line-height: 18px;
            margin: 0;
            padding: 0px 0px 20px 0px;
        }

        .Modal-close {
            color: gray;
            font-size: 25px;
            padding: 0px 15px;
            color: rgba(0, 0, 0, 0.5);
            position: inherit;
            background: none;
        }
    }

    .Modal-content {
        padding: 5px 15px;

        .Accordion-header {
            background: url('/Assets/Images/nav-down.svg') #fff 98% center no-repeat;
            padding: 5px 15px;
            margin: 0px -15px;
            border-top: 1px solid rgba(221, 221, 221, 0.5);
            background-size: 20px auto;

            &.is-active {
                background: url('/Assets/Images/nav-up.svg') #fff 98% center no-repeat;
                background-size: 20px auto;
            }

            &:first-child {
                border-top: none !important;
            }
        }

        p {
            margin: 0;
            padding: 0 16px 12px 0px;
            font-size: 13px;
            color: rgba(0,0,0,0.5);
            line-height: 16px;
        }
    }

    .Modal-footer {
        margin-top: 0;
        padding: 15px;
        border-top: 1px solid rgba(220, 220, 220, 0.4);
        background: rgba(220, 220, 220, 0.2);
        display: flex;
        align-items: baseline;
        -webkit-box-align: center;
        justify-content: space-between;
        flex-wrap: wrap;
        font-weight: 600;
        font-size: 15px;

        a.Link {
            text-decoration: none;
        }
    }
}
