﻿@import "../common/variables";

.Hero {
    width: 100%;
    position: relative;

    @media screen and (min-width: 768px) {
        display: flex;
    }
}

.Hero--contentLeft {
    @media screen and (min-width: 768px) {
        flex-direction: row-reverse;
    }
}

.Hero-content {
    background-image: url(/Assets/Images/banner-content-bg-mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    margin-left: auto;
    padding: 40px 20px;
    z-index: 1;
    position: relative;
    color: #fff;
    margin-top: -15px;


    @media screen and (max-width: 767px) and (min-width: 450px) {
        margin-top: -25px;
    }

    h2,
    p {
        color: #fff;
    }

    @media screen and (min-width: 768px) {
        padding: 50px;
        margin-top: 0;
        background-image: url(/Assets/Images/banner-content-bg.png);
        width: ~"calc(15px + 50%)";
        margin-left: -15px;
        min-height: 370px;

        .Hero--contentLeft & {
            margin-left: 0;
            margin-right: -15px;
        }
    }

    @media screen and (min-width: 1025px) {
        width: ~"calc(15px + 100%/3)";
    }
}

    .Hero-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 0;
        width: 100%;
        z-index: 0;
        min-height: 200px;

        @media screen and (min-width: 768px) {
            width: 50%;
            background-position: center;
        }

        @media screen and (min-width: 1025px) {
            width: ~"calc(100%/3*2)";
        }
    }