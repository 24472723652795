﻿.Tooltip {
    position: relative;
    display: inline-block;
}

.Tooltip-trigger {
    display: inline-flex;
    background: #e21f3d;
    color: #fff;
    font-weight: 600;
    font-size: 10px;
    border-radius: 100%;
    width: 15px;
    height: 14px;
    text-align: center;
    justify-content: center;
}

.Tooltip .Tooltip-text {
    visibility: hidden;
    width: 14em;
    background-color: #e21f3d;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 9;
    top: -1em;
    left: 100%;
    margin-left: 1em;
    opacity: 0;
    transition: opacity 1s;
    font-size: 13px;
    padding: 8px;
}

.Tooltip .Tooltip-text::after {
    content: "";
    position: absolute;
    top: 1.5em;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #f3f3f3 transparent transparent;
}

.Tooltip input {
    display: none;
}

.Tooltip input:checked + .Tooltip-text {
    visibility: visible;
    opacity: 1;
}

.unionAlert {
    background: #e21f3d;
    padding: 8px;
    display: none;

    p {
        color: white;
        margin-bottom: 0;
    }
    &.is-active{
        display: block;
    }
}