﻿@import "../common/variables.less";
/*#

Display a loading animation to let user know that something is processing in the background. 

Examples:

<div>
    <span class="Loading Loading--spinning">
        <span class="Loading-icon"></span>                        
        <span class="Loading-text">Your profile is loading...</span>
    </span>
    
    <span class="Loading Loading--dots">
        <span class="Loading-icon"></span>                        
        <span class="Loading-text">Your profile is loading...</span>
    </span>
    
    <button class="Button" data-module="toggler" data-toggler="contentElement: '.js-loading1'">
        Start loading 
        <span class="Loading Loading--spinning Loading--small Loading--fill u-hidden js-loading1">
            <span class="Loading-icon"></span>
        </span>
    </button>
</div>

*/

 
@Loading-iconColor: #d72141;
@Loading-size:20px;

.Loading {
    display: inline-block;
    padding: 0;
    text-align: center;
    vertical-align: middle;
}

.Loading--full {
    width: 100%;
}
.Loading--hidden{
    display:none;
}
.is-loading + .Loading--hidden,
.is-loading .Loading--hidden {
    display: block;
}
.Loading--select{
    position:absolute;
    right:4px;
    top:-1px;
}
.Loading--fill {
    position: absolute;
    background: #fff;
    padding: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Loading--compact {
    padding: 0;
}

.Loading--xsmall {
    font-size: .5em;
}

.Loading--small {
    font-size: 12px;
}

.Loading--large {
    font-size: 18px;
}



.Loading--spinning {
    .Loading-icon {
        display: inline-block;
        height: @Loading-size;
        width: @Loading-size;
        border: 4/16em solid @Loading-iconColor;
        border-right-color: transparent;
        border-radius: 50%;
        animation: rotate 0.9s infinite linear;
    }
}

.Loading--dots {
    .Loading-icon:before,
    .Loading-icon:after,
    .Loading-icon {
        border-radius: 50%;
        width: @Loading-size;
        height: @Loading-size;
        animation-fill-mode: both;
        animation: circlePulse 1.5s infinite ease-in-out;
    }
    
    .Loading-icon {
        display: inline-block;
        color: @Loading-iconColor;
        position: relative;
        top: -@Loading-size;
        transform: translateZ(0);
        animation-delay: -0.16s;
    }
    
    .Loading-icon:before {
        left: -(@Loading-size+.5em);
        animation-delay: -0.32s;
    }
    
    .Loading-icon:after {
        left: (@Loading-size+.5em);
    }
    
    .Loading-icon:before,
    .Loading-icon:after {
        content: "";
        position: absolute;
        top: 0;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes circlePulse {
    0%,
    80%,
    100% {
        box-shadow: 0 @Loading-size 0 -(@Loading-size/2);
    }
    40% {
        box-shadow: 0 @Loading-size 0 0;
    }
}

.Loading-large {
    .Loading-icon {
        height: @Loading-size * 4;
        width: @Loading-size * 4;
        border-width: 6/16em;
    }
}