@import "../common/variables";

.Button {
    border-radius: 2px;
    font-family: @fontFamilyPrimary;
    padding: 0 1rem;
    font-size: unit(13 / @baseFontSize, rem);
    line-height: 2.75rem !important;
    font-weight: 600;
    color: #283646;
    text-decoration: none;
    margin: 0;
    display: inline-block;
    position:relative;
    margin-bottom: 1em;
    &:hover {
        opacity: 0.9;
    }

    &:focus {
        outline: 1px dotted @colorLinkHover;
    }
}

.Button--clear {
    background-color: transparent;
    color: #fff;
}

.Button--red {
    background-color: #c61b35;
    color: #fff;
}

.Button--gray, .Button--grey {
    background-color: #eee;
    color: #c61b35;

    &:after {
        content: " ";
        position: relative;
        background-image: url(/Assets//Images/arr-btn-right-red.svg);
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        float: right;
        margin-top: 11px;
    }
}
.Button--round{
    border-radius: 30px;
}
.Button--hero {
    background: #fff;
    width: 100%;
    text-align: center;

    &:after {
        content: '';
        width: 14px;
        height: 14px;
        display: inline-block;
        background: url("/Assets/Images/arr-btn-right.svg") no-repeat;
        background-size: 100% auto;
        margin: 2px 0 0 13px;
        vertical-align: text-top;
    }

    @media screen and (min-width: 642px) {
        width: auto;
        text-align: left;
    }
}
