@import "../common/variables";

.Link {
    color: @colorLink;

    &:hover {
        color: @colorLinkHover;
    }

    &:focus {
        color: @colorLinkHover;
        outline: 1px dotted @colorLinkHover;
    }
}

.Link--white {
    color: white;

    &:hover {
        color: white;
    }

    &:focus {
        color: white;
    }
}

.Link-heading {
    text-decoration: none;
}