﻿@import "../common/variables";

/*
Note: these ara not the only styles applied to .big-menu, but mainly responsive tablet meny and visual redesign.
*/

.header-bar {
	/*Menu button*/
	.main-hamburger {
		a {
			margin: 28px 0px 0px 28px;

			img {
				top: 13px;
			}
		}

		&.big_menu_active a {
			background: rgb(247, 247, 247);
			box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.1);

			&:before {
				content: none;
			}
		}
	}
	/*Drop down menu*/
	.big_menu {
		background: rgb(247, 247, 247);
        z-index: 100;
		.columns {

			ul li {
				padding: 10px 0;
			}

			.menu {

				& > li:first-child > a:first-child {
					background: #e21f3d;
					color: #fff;
					font-weight: 600;
					margin-bottom: 0;

					.Accordion-header {
						background: none;
						padding: 0;
						margin-top: -3px;
						float: right;
					}
				}
				/*Accordion items*/
				.has-sub a span {
					&:hover {
						&:after {
							background-image: url("/Assets/Images/arr-btn-down.svg");
							background-color: #fff;
							border-color: #fff;
						}
					}

					&:after {
						font-family: FontAwesome;
						background-image: url("/Assets/Images/arr-btn-down-white.svg");
						display: inline-block;
						width: 21px;
						height: 21px;
						content: "";
						float: right;
						border: 3px solid #e21f3d;
					}

					&.is-active:after {
						-moz-transform: scaleY(-1);
						-o-transform: scaleY(-1);
						-webkit-transform: scaleY(-1);
						transform: scaleY(-1);
						filter: FlipV;
						-ms-filter: "FlipV";
					}
				}

				ul.sub-menu {
					background: #fff;
					padding: 7px 15px;

					li {

						&:not(:last-child) {
							border-bottom: 1px solid #e2e2e2;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 642px) {
		.big_menu {
			width: 33.33333%;
			left: 66.7%;
		}
	}

	@media screen and (min-width: 1025px) {
		.big_menu {
			width: 100%;
			left: 0;

			ul.menu > li:first-child > a:first-child .Accordion-header {
				display: none;
			}

			ul.sub-menu.Accordion-panel {
				display: block !important;
			}
		}
	}
}