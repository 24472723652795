﻿@import "../common/variables";

/*#
<span class="InputGroup" data-date="" data-date-format="dd-mm-yyyy" data-module="datepicker">
    <input type="text" placeholder="Input field" class="InputGroup-input">
    <a href="#" class="InputGroup-addon Icon Icon--calendar"></a>
</span>

<span class="InputGroup InputGroup--left" data-date="" data-date-format="dd-mm-yyyy" data-module="datepicker">
    <a href="#" class="InputGroup-addon Icon Icon--calendar"></a>
    <input type="text" placeholder="Input field" class="InputGroup-input">
</span>

<span class="InputGroup InputGroup--left">
    <span class="InputGroup-addon Icon Icon--search"></span>
    <input type="text" placeholder="Sök på webbplatsen" class="InputGroup-input">
</span>

<span class="InputGroup">
    <input type="text" placeholder="Sök på webbplatsen" class="InputGroup-input">
    <span class="InputGroup-addon Icon Icon--search"></span>
</span>

<div style="background: #eee; padding: 1em;">
    <span class="InputGroup InputGroup--left InputGroup--borderless">
        <span class="InputGroup-addon Icon Icon--search"></span>
        <input type="text" placeholder="Sök på webbplatsen" class="InputGroup-input">
    </span>
</div>
*/

.InputGroup {
    display: table;
    width: auto;
    border: 2px solid @colorGrey;
    vertical-align: middle;
}

.InputGroup-input {
    display: table-cell;
    border-radius: 0 !important;
    border-right: none !important;
    width: 100%;
    border: none !important;
    margin: 1px 0; // lets the border shine through
}

.InputGroup-addon {
    background: #fff;
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0.5em 0.6em;
    border-left: 0;
    font-size: 20/16em;
    text-decoration: none;
    line-height: 0.95em;
    text-align: center;

    a& {
        border-left: 1px solid @colorGrey;
    }

    a&:hover {
        background: @colorGrey;
    }
}

.InputGroup--left {
    a.InputGroup-addon {
        border-right: 1px solid @colorGrey !important;
    }
}

.InputGroup--borderless {
    background: #fff;
    border: none !important;

    .InputGroup-input,
    .InputGroup-addon {
        border: none !important;
    }

    // this should be refactored. goal is to remove padding when Addon is just an icon
    &.InputGroup--left {
        .InputGroup-input {   
            padding-left: 0;

            &:focus {
                box-shadow: none;
            }
        }
    }
}
