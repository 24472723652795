﻿@import "../common/variables";

.post-entry {
    label {
        display: inline-block;
    }

    .Form-inputs {
        label {
            display: block;
            margin-bottom: 10px;
        }
    }
}

.Form--application {

	.Form-row {
		margin: 0 -0.75em;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;

		@media @bpMedium {
			flex-wrap: nowrap;
		}
	}

	.Form-col {
		margin: 0 0.75em;
		padding: 0;

		@media @bpMedium {
		}
	}

	.Form-label {
		font-family: "museo-slab",serif;
		cursor: default;

		&--tiny {
			display: inline-block;
			margin-right: 5px;
		}

		.field-validation-valid {
			display:none;
		}
	}

	.Form-input {

		&input:invalid {
			border-color: @colorRed;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type=number] {
			-moz-appearance: textfield;
		}
	}

	.select2-wrapper {
		position: relative;

		.select2-container {
			position: absolute;
			top: 0;
			opacity: 0;

			select:hover {
				background-color: #FAFAFA;
				border-color: #cccccc;
			}

			span {
				width: 100%;
				display: block;
			}
		}
	}
}

.select2-container {
	min-width: 302px;
	-webkit-appearance: none;
	border-radius: 0;
	background-color: #FAFAFA;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…J2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
	background-position: 100% center;
	background-repeat: no-repeat;
	border-style: solid;
	border-width: 1px;
	border-color: #cccccc;
	color: rgba(0, 0, 0, 0.75);
	display: block;
	font-size: 0.875rem;
	margin: 0 0 1rem 0;
	padding: 0.5rem;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-family: open-sans, Arial, sans-serif;

	.select2-search__field {
		border: none;
		border-bottom: 1px solid #ccc;
		background-color: transparent;
		box-shadow: none;
		padding: 0;
	}

	.select2-results__options {
		overflow-x: hidden;
		max-height: 222px;
	}


	li {
		margin-bottom: 1em;
		font-size: 0.875rem;
	}
}