﻿@import "../common/variables";

.Calendar {
    border-top: 5px #ECECEC solid;
    margin-top: 20px;
    padding: 0 22px;
}

.Calendar-title {
    font-size: 15px;
    font-family: "museo-slab",serif;
    font-weight: 500;
    margin: 20px 20px;

    &:before {
        font-family: FontAwesome;
        content: '\f073';
        color: #000;
        vertical-align: middle;
        margin-right: 10px;
        font-size: 20px;
    }
}


.CalendarItem {
    background: #F8F8F8;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}

.CalendarItem-panel {
    padding: 20px 11px;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

.CalendarItem-type {
    background: #E91D41;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    font-size: 16px;
    margin: 0;
}

.CalendarItem-type--Möte {
    background: #4A90E2;
}

.CalendarItem-type--Konferens {
    background: #32A845;
}

.CalendarItem-type--Kongress {
    background: #F5A623;
}

.CalendarItem-name {
    text-align: center;
    font-size: 14px;
    -ms-word-break: break-word;
    word-break: break-word;
    font-family: "museo-slab",serif;
    font-weight: 500;
}

.CalendarItem-button {
    margin: 0 auto !important;
    display: block !important;
    min-width: 100px;
}

.CalendarItem-meta {
    border: 1px solid #ECECEC;
    border-width: 1px 0;
    display: flex;
    padding: 11px;
    justify-content: space-between;
}

.CalendarItem-seats {
    font-size: 12px;
    margin: 0;

    &:before {
        float: left;
        content: "\f0c0";
        font-family: FontAwesome;
        font-size: 18px;
        margin-right: 5px;
    }
}

.CalendarItem-last {
    border-bottom: 1px solid #ECECEC;
    text-align: center;
    padding: 11px;
    margin: 0;
}

.CalendarItem-date {
    font-size: 12px;
    margin: 0;

    &:before {
        float: left;
        content: "\f073";
        font-family: FontAwesome;
        font-size: 18px;
        margin-right: 5px;
    }
}

.CalendarItem--highlight {
    background: none;
    padding: 0;
    position: relative;
    margin-bottom: 40px;

    .CalendarItem-last {
        border: 0;
        text-align: right;
        padding: 0;
    }

    .CalendarItem-meta {
        margin-bottom: 22px;
    }

    .CalendarItem-name {
        text-align: left;
        font-family: 'open-sans', Arial, sans-serif;
    }

    .CalendarItem-type {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        padding-left: 80px;
        text-align: left;
    }
}

.CalendarItem-datePanel {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    padding: 11px;
    z-index: 3;
    text-align: center;
}

.CalendarItem-media {
    position: relative;
    margin-bottom: 20px;

    @media (min-width:1025px) {
        margin-bottom: 0;
    }
}

.CalendarItem-image {
    width: 100%;
}

.CalendarItem-title {
    margin-top: 0;
    padding: 0;
    line-height: 1;
    margin-bottom: 20px;
    letter-spacing: -0.55px;
    font-weight: 800;
}

.CalendarItem-dateHighlight {
    font-size: 32px;
    margin: 0;
    line-height: 1.2;
}

.CalendarItem-dateHighlightStart {
    margin: 0;
    font-size: 14px;
    letter-spacing: 2px;
}

.CalendarItem-container {
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -ms-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    justify-content: flex-end;
    padding: 0 0 30px 0;
}
.CalendarItem-short{
    color:@colorRed;
    
    padding:0;
    text-align:center;
    padding:10px 0 15px 0;
     border-bottom: 1px solid #ECECEC;
     p{
         margin:0;
     }
}
.CalendarItem-shortText{
    font-weight:bold;
    font-style:italic;
}
