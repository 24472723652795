﻿@import "../common/variables";

/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.datepicker {
	top: 0;
	left: 0;
	padding: 4px;
	margin-top: 1px;
	border-radius: 4px;
	min-width: 285px;
	margin-top: 0.9em;

	/*&:before {
		content: '';
		display: inline-block;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-bottom: 7px solid #ccc;
		border-bottom-color: rgba(0,0,0,.2);
		position: absolute;
		top: 40px;
		right: 20px;
	}*/

	&-noLabel&:before {
		top: 20px;
	}

	&-inLine {
		min-width: 0;
		margin-top: 0;
		position: relative;

		input {
			margin-bottom: 0;
		}
	}

	&-inLine:before {
		content: unset;
	}

	&:after {
		content: '';
		display: none;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid #fff;
		position: absolute;
		top: 38px;
		right: 20px;
	}

	> span {
		position: relative;
		z-index: 9;

		a {
			background-color: transparent;
		}
	}

	> div {
		display: none;
	}

	table {
		width: 100%;
		margin: 0;
	}

	td,
	th {
		text-align: center;
		width: 20px;
		height: 20px;
		border-radius: 4px;
	}

	td {
		text-align: center;
		padding: .3em .5em;

		&.day:hover {
			background: @colorGrey;
			cursor: pointer;
		}

		&.day.disabled {
			color: @colorGrey;
		}

		&.old,
		&.new {
			color: @colorGrey;
		}

		&.active,
		&.active:hover {
			background: @colorBlue;
			color: #fff;
			text-shadow: 0 -1px 0 rgba(0,0,0,.25);
		}

		span {
			display: block;
			width: 47px;
			height: 54px;
			line-height: 54px;
			float: left;
			margin: 2px;
			cursor: pointer;
			border-radius: 4px;

			&:hover {
				background: @colorGrey;
			}

			&.active {
				background: @colorBlue;
				color: #fff;
				text-shadow: 0 -1px 0 rgba(0,0,0,.25);
			}

			&.old {
				color: @colorGrey;
			}

			&.disabled {
				background: #f5f5f5;
				color: @colorTextLight;
			}
		}
	}

	th {
		&.switch {
			width: 145px;
		}

		&.next,
		&.prev {
			font-size: 16 * 1.5;
		}
	}

	thead tr:first-child th {
		cursor: pointer;

		&:hover {
			background: @colorGrey;
		}
	}
}

.input-append,
.input-prepend {
    &.date {
        .add-on i {
            display: block;
            cursor: pointer;
            width: 16px;
            height: 16px;
        }
    }
}

.Editing .datepicker-inLine {
	display: table;
	&:before {
		top: 16px;
		right: 4px;
		content: '';
	}
	span.Edit {
		display: table;
	}
}