﻿@import "../common/variables.less";

@Members-background:#D8173C;

.Members{
    background:#D8173C;
    width:100%;
    overflow:hidden;    
    padding:20px;
   
        display: flex;
       
        justify-content: space-around;
    
    @media (min-width: 48em){
        display:block;
    }
}
.Members-panel{
   
    text-align:center;
    margin-bottom:20px;
    
        width:50%;
        float:left;
        position:relative;
       
    
    @media (min-width: 48em){
        float:none;
        position:static;
        transform:none;
        width:100%;
    }
    @media (min-width: 64.063em){
        width:50%;
        float:left;
        position:relative;
        top:50%;
        transform:translateY(-50%);
    }
}
.Members-title{
    color:#fff;
    margin:0;
    
}
.Members-number{
    
    color:#fff;
    display:block;
    font-weight: 800;
    font-family:'open-sans', Arial, sans-serif;
    line-height:1;
    font-size:40px;
    @media (min-width: 641px){
        font-size:60px;
    }
}
