﻿.grandidcontainer {
    width:100%;
    height:650px;
}
.spinnercontainer {
    width:400px;
    height:300px;
    text-align:center;
    padding-top:50px;
}
.hide-element {
    display: none;
}
