@import "../common/variables";

.Logotypes {
    background: #fff;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    overflow: hidden;
    overflow-x: auto;
    align-items: center;
    flex-direction: row;
}

.Logotypes .LinkBlock {
    border-bottom-width: 0;
    padding: 0;

    &:before,
    &:after {
        content: none;
    }

    &:focus {
        outline: 1px dotted @colorLinkHover;
    }

    img {
        max-width: 100px;
        max-height: 50px;
        margin: 1rem .5rem;
    
        @media (min-width: 1024px) {
            max-width: 150px;
            max-height: 75px;
        }
    }
}
