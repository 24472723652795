﻿/*#
Component for showing a modal window on page.
Can be used in conjunction with the "modal" js-module.
The dialog is not suitable for content that is taller than the screen height.

<div>
    <p><a href="#" class="Button" data-module="modal" data-modal="contentElement: '.js-demoModal'">Open modal</a></p>
    
    <section class="Modal js-demoModal">
        <div class="Modal-dialog">
            <h3 class="Heading Heading--border u-marginTopZero">Modal header</h3>
            <a class="Modal-close js-close" href="#">&times;</a>
            
            <div class="Modal-content">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean maximus eget ipsum id mattis. Aliquam nec erat sed justo ullamcorper congue eget id orci. Phasellus eleifend rhoncus lacinia.</p>
            </div>
            
            <div class="Modal-footer">
                <a href="#" class="Link Link--gray u-marginRight js-close">Cancel</a>
                <a href="#" class="Button Button--wide">Send</a>
            </div>
        </div>
    </section>
</div>
*/

@import "../common/variables.less";

.Modal {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding: 1em;
    align-items: center;
    z-index: 1000;
    &:after {
        content: '';
        background: rgba(0,0,0,.6);
        position: fixed;
        backface-visibility: hidden; // increases scroll performance
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
    }

    &.Modal--active {
        display: flex;
    }
}

.Modal-dialog {
    position: relative;
    background: #fff;
    margin: 0 auto;
    z-index: 101;
    width: 100%;
    max-width: 550px;
    padding: 1em;
    box-shadow: 2px 2px 5px #00000047;
}

.Modal-footer {
    /*background: @colorLightGrey;*/
    margin-top: 1.5em;
    padding: 1em 0 0;
    text-align: right;
    border-top: 2px dashed @colorGrey;
}

.Modal-close {
    position: absolute;
    top: .5em;
    right: .6em;
    font-size: 1.6em;
    text-decoration: none;
    padding: 0 0.3em 0.2em;
    background: @colorGrey;
    line-height: 1em;
    border-radius: 50%;
    color: #fff;

    &:hover {
        /*background: @colorDarkGrey;*/
        background: @colorGrey;
        color: #fff;
    }
}

.Modal--fullSize {
    .Modal-dialog  {
        max-width: @widthContainer;
    }
}