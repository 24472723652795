﻿@import "../common/variables";

.Tabs {
	margin-top: 1.5em;

	.Tabs-list {
		margin: 0;

		.Tabs-item {
			display: inline-block;
			padding: 9px 15px;
			margin-right: 2px;
			background: #e62245;
			font-family: 'open-sans', Arial, sans-serif;
			text-align: center;
			color: #fff;
			font-weight: 700;
			border-radius: 3px 3px 0 0;
			-webkit-border-radius: 3px 3px 0 0;
			-moz-border-radius: 3px 3px 0 0;
			opacity: 0.6;
			transition: all ease 0.1s;

			&:hover,
			&.is-active {
				opacity: 1;
			}
			&:hover:not(.is-active) {
				cursor: pointer;
			}
		}
	}

	.Tabs-panel {
		display: none;
		transition: all ease 0.1s;

		&.is-active {
			display: block;
			border-top: 1px solid #e62245;
		}


		.Accordion-header {
			transition: all ease 0.1s;
			cursor: pointer;

			&:focus,
			&:hover {
				.Accordion-headerLink {
					color: #e62245;
				}
			}
		}
	}
}
/* Select adjacent to tabs on uppdatera uppdrag form */
.Select-input.MembershipStatus {
	margin: 0;
}