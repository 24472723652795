﻿.PowerBI {
    padding-left: 0px;
    position: relative;
    width: 100%;
    background: white;

    &-preamble {
        border-bottom: 1px solid #ddd;
    }

    #report-container {
        height: 75vh;
        width: 100%;

        iframe {
            border: none;
            width: 100%;
            height: 75vh;
            /*box-shadow: 0 0 5px #00000036;*/
        }
    }

    &-statistics {

        ul {
            list-style: none;
            padding-left: 0;
            margin-left: 0;
        }

        .Button {
            margin-bottom: 1em;
            width: 50%;
            font-size: 1.3em;
            font-weight: 600;
        }

        a.Button.Button--gray {
        }
    }
}
