﻿@import "../common/variables";

.Footer--main {
    overflow: hidden;
    background-image: url(/Assets/Images/footer.svg);
    background-size: cover;
    background-position: top center;
    padding: 60px 10px 40px;
    text-align: center;
    font-family: @fontFamilyPrimary;

    @media (min-width: 768px) {
        padding: 0;
        text-align: left;
    }
}

.Footer-container {
    justify-content: space-between;
    align-items: center;
    max-width: 738px;
    margin: auto;

    @media (min-width: 768px) {
        display: flex;
        padding: 80px 0;
    }

    @media (min-width: 1023px) {
        max-width: 960px;
    }

    @media (min-width: 1229px) {
        max-width: 1170px;
    }
}

.Footer-nav {
    margin-bottom: 2rem;

    img {
        max-height: 50px;
    }

    @media (min-width: 768px) {
        margin-bottom: 0;
    }
}

.Footer-contact {
    text-align: center;

    p {
        line-height: 2em;
        text-align: left;
    }


    @media (min-width: 768px) {
        text-align: right;
        display: flex;
    }
}

.Footer-social {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: 768px) {
        justify-content: flex-end;
    }

    a {
        margin-left: .75rem;
    }
}
