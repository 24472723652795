﻿
.LinkBlock{
    color:#000;
    text-decoration:none;
    border-bottom:5px solid #ececec;
    display:block;
    font-size:14px;
    padding:22px;
    &:before{
        font-family: FontAwesome;
        content:'';
        color:#000;
        vertical-align:middle;
        margin-right:10px;
        font-size:20px;
    }
    &:after{
        content:"»";
        margin-left:5px;
    }

}
.LinkBlock--list{
    &:before{
       content:'\f022';
    }
}
.LinkBlock--document{
    &:before{
        content:'\f0f6';
    }
}
.LinkBlock--contact{
    &:before{
        content:'\f2bc';
    }
}
.LinkBlock--news{
    &:before{
       content:'\f1ea';
    }
}
.LinkBlock--calendar{
    &:before{
       content:'\f073';
    }
}
.notification{
    padding:22px;
    border-bottom:5px solid #ececec;
    white-space:nowrap;
}