﻿@import "../common/variables";

.StartPage {
    @media (max-width: 641px) {
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        margin-left: 10px;
        margin-right: 10px;
    }
    // Grid fix
    .Grid.Grid--gutter + .Grid.Grid--gutter {
        margin-top: 10px;
    }
    // Post fix
    .post {
        background: #fff;
        margin: 0;
        width: 100%;
    }

    .post-entry {
        padding: 0;
        padding-top: 21px;
        padding-left: 21px;
        padding-right: 21px;
    }

    .post-entry .post-image,
    .form--withPostImage .Form__Image {
        height: 240px;
        overflow: hidden;
        margin-left: -21px;
        margin-right: -21px;
        margin-top: -21px;
        margin-bottom: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
            object-fit: cover;
            width: 100%;
            min-height: 240px;
        }
    }

    // List fix
    .List-item:last-child {
        margin-bottom: 0;
    }
    // Notification fix
    .notification {
        margin-top: 0;
        background: #fff;
        width: 100%;

        a {
            font-family: @fontFamilyPrimary;
        }
    }
    // Form block fix
    form {
        background: #fff;
        width: 100%;

        h2 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        img {
            width: 100px;
            margin: auto;
            display: block;
        }

        .Form__Element__Caption,
        .Form__Description,
        .Form__Status__Message.hide {
            display: none !important;
        }

        .FormTextbox {
            width: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
            float: none !important;
        }

        .FormTextboxHp {
            position: absolute;
            top: 0;
            left: 0;
            height: 0;
            width: 0 !important;
            z-index: -1;
        }

        .FormSubmitButton {
            top: 0 !important;
            padding: 0 1rem !important;
            line-height: 2.75rem !important;
            font-weight: 600 !important;
            margin: 0 !important;

            &:focus {
                outline: 1px dotted @colorLinkHover !important;
            }
        }

        .Form__Element__ValidationError {
            position: relative !important;
            top: -1em;
        }
    }
    /* Startpage episerverforms with article-post-look */
    .post--withForm .EPiServerForms {
        padding: 0 0 20px;
    }

    .form--withPostImage {
        padding: 0 22px 20px;
    }
}
