﻿@import "../common/variables";

.News,
.widget-area--startPage{
    footer{
            text-align:right;
        }
    .post{
        border:0;
        
        .button,
        .button:hover{
            background:none;
            text-decoration:underline;
            font-size:12px;
           
            color:#e21f3d;
            padding:0;
            &:after{
                background:none;
                width:0;
                height:0;
            }
        }
    }
    .post-title, img{
        margin-bottom:20px!important;
    }
}
.News-highlight{
    border-bottom:1px solid #ccc;
    margin:20px;
    padding-bottom:20px;
    .post-entry{
        padding:0;
    }
    .post footer{
        padding:0;
    }
    .post-title{
        font-size:28px!important;
        line-height:34px!important;
    }
}

.News-title{
    font-size:15px;
    padding:0;
    font-family: "museo-slab",serif;
    font-weight: 500;
    margin:20px 20px 0 20px;
    &:before{
        font-family: FontAwesome;
        content:'\f1ea';
        color:#000;
        vertical-align:middle;
        margin-right:10px;
        font-size:20px;
    }
}

.post-entry {
    h1, h2, h3, p, span, ul li, ol li, li {
        img.ArticleImage--right {
            margin-bottom: .6rem;

            @media @bpMedium {
                max-width: 35%;
                float: right;
                margin-left: 1.5rem;
                margin-bottom: 1.5rem;
            }

            @media @bpLarge {
                max-width: 55%;
            }
        }


        img.ArticleImage--left {
            margin-bottom: .6rem;

            @media @bpMedium {
                max-width: 35%;
                float: left;
                margin-right: 1.5rem;
                margin-bottom: 1.5rem;
            }

            @media @bpLarge {
                max-width: 55%;
            }
        }
    }
}
 