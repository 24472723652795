﻿@import "../common/variables";

.Membershipservice-msg {
	border: 1px solid #ddd;
	width: 100%;
}

.Membershipservice-msg > div {
	display: flex;
	align-items: flex-start;
}

.Membershipservice-msg p {
	flex: 1;
	margin-bottom: 0;
	margin-right: @spaceMedium;
}

.Membershipservice-msg.Updated .Membershipservice-info {
	display: none !important;
}

.Membershipservice-msg.Updated .Membershipservice-confirmation {
	display: flex !important;
}