﻿@import "../common/variables";

.accordion-text {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #e8e8e8;
    border-radius: 4px;

    &__button {
        margin: 0;
        background-color: white;
        padding: .5rem;
        width: 100%;
        border-radius: 2px;
        background: url('/Assets/Images/nav-down-red.svg') #fff 98% center no-repeat;
        background-size: 20px auto;

        &.is-active {
            background: url('/Assets/Images/nav-up-red.svg') #fff 98% center no-repeat;
            background-size: 20px auto;
            border-bottom: 1px solid #e8e8e8;
        }

        &:hover,
        &:active,
        &:focus {
            opacity: 1;
        }
    }

    &__heading {
        padding: 0;
        margin: 0;
        text-align: start;
        font-size: 18px;
    }

    &__panel {
        background: #fff;
        padding: .5rem;
    }
}
