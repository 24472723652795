﻿@import "../common/variables";

.GlobalMessage {
	position: relative;
	z-index: 99;
	color: black;
	padding-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #e21f3d;

	p {
		font-size: 20px;
		color: #ffffff;
		font-weight: 800;
	}
}