﻿/*# 

<table class="Table">
    <thead>
        <tr>
            <th>Header 1</th>
            <th>Header 2</th>
            <th><a href="#">Header 3</a></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Row col 1</td>
            <td>Row col 2</td>
            <td>Row col 3</td>
        </tr>
        <tr>
            <td>Row col 1</td>
            <td>Row col 2</td>
            <td>Row col 3</td>
        </tr>
    </tbody>
</table>

*/

@import "../common/variables";

@Table-stripedColor: lighten(#ECECEC, 10%);

.Table {
    
    th,td{
        font-size:12px;
    }
}

.Table--striped {
    tbody tr:nth-child(odd) {
        background-color: @Table-stripedColor;
    }
}

.Table--bordered {
    td {
        border-bottom: 1px solid #ececec;
    }
}

.Table--alignMiddle {
    td {
        vertical-align: middle;
    }
}
 
@media only screen and (max-width: 1025px), print {
    /**
     * Enable this modifier to display a flat table layout in small screens.
     * Utilizing a data-th attribute to display headings before each column.
     *
     * Example HTML:
     *   <tr>
     *       <td data-th="Movie Title">Star Wars</td>
     *       <td data-th="Genre">Adventure, Sci-fi</td>
     *       <td data-th="Year">1977</td>
     *       <td data-th="Gross">$460,935,665</td>
     *   </tr> 
     */
     
    .Table--flatInSmall {
        tr {
            border-top: 1px solid #ececec;
            border-bottom: 1px solid #ececec;
        }
        
        th {
            display: none;
        }
        
        td {
            display: block;
 
            &:before {
                content: attr(data-th) ": ";
                display: inline-block;
                font-weight: bold;
                vertical-align: top;
                margin-right: .5em;
                
                // use a fixed width to align headings
                //width: 7em;
            }
        }
    }
}