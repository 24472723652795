/*
Theme Name: Livs
Description: Dedicated Wordpress theme for Livs
Fabian1
*/


/* ==========================================================================
	Variables
/* ========================================================================== */


/* Colors */


/* Font Sizes */


/* Layout */


/* Content Images */


/* Sidebar */


/* Fallbacks / browser support */


/* ==========================================================================
	Media queries
/* ========================================================================== */


/* Default bootstrap breakpoints */


/* Custom breakpoints */


/* ==========================================================================
	Images
/* ========================================================================== */


/* Uncomment when using sprites: */


/* Uncomment when using sprites for retina images: */


/* Uncomment when using sprites fallback for IE8: */


/* Project supports: */


/* Paths: */


/* line 15, ../../../../source/wp-content/themes/livs/css/_mixins.scss */

.ie8 .search-form.mobile-clone input[type=text], .search-form.mobile-clone .ie8 input[type=text], .ie8 .search-bar input[type=text], .search-bar .ie8 input[type=text], .ie8 .home-banner .banner-btn .button, .home-banner .banner-btn .ie8 .button, .ie8 .post footer .button, .post footer .ie8 .button, .ie8 .share-window, .ie8 .share-window .share-input input, .share-window .share-input .ie8 input, .ie8 .share-window > a, .ie8 .add-to-fav .wpfp-span, .add-to-fav .ie8 .wpfp-span, .ie8 .primary-widget-area .tagcloud a, .primary-widget-area .tagcloud .ie8 a, .ie8 .secondary-widget-area .tagcloud a, .secondary-widget-area .tagcloud .ie8 a, .ie8 .primary-widget-area .widget-user-buttons a, .primary-widget-area .widget-user-buttons .ie8 a, .ie8 .secondary-widget-area .widget-user-buttons a, .secondary-widget-area .widget-user-buttons .ie8 a, .ie8 .primary-widget-area .widget-favorites-footer .button, .primary-widget-area .widget-favorites-footer .ie8 .button, .ie8 .secondary-widget-area .widget-favorites-footer .button, .secondary-widget-area .widget-favorites-footer .ie8 .button, .ie8 .primary-widget-area input, .primary-widget-area .ie8 input, .ie8 .secondary-widget-area input, .secondary-widget-area .ie8 input {
  behavior: url("pie/PIE.htc");
}

@-moz-keyframes button-spin {
  from {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes button-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* ==========================================================================
	Base
/* ========================================================================== */


html {
  height: auto;
  height: 100%;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'open-sans', Arial, sans-serif;
  color: #000;
  font-size: 12px;
  line-height: 1.4;
  background: #f3f3f3 url("/Assets/Images/background.png");
  -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}


.gm-style img {
  max-width: none;
}

p {
  font-size: 12px;
  line-height: 18px;
}

/* ==========================================================================
	Links
/* ========================================================================== */


a {
  color: #d72141;
  text-decoration: underline;
}

a img {
  border: 0;
}

a:focus, a:hover {
  color: #681020;
}

a:focus {
  outline: 0;
}

a:active, a:hover {
  outline: 0;
}

.search-form input::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.9;
}

.search-form input:-moz-placeholder {
  color: #fff;
  opacity: 0.9;
}

.search-form input::-moz-placeholder {
  color: #fff;
  opacity: 0.9;
}

.search-form input:-ms-input-placeholder {
  color: #fff;
  opacity: 0.9;
}

/* =============================================================================
	Helper Classes
/* ========================================================================== */


.clearfix:after, .cf:after, .post header:after, .primary-widget-area .tagcloud:after, .secondary-widget-area .tagcloud:after, .primary-widget-area .contact-person:after, .secondary-widget-area .contact-person:after, .primary-widget-area .widget-user-buttons:after, .secondary-widget-area .widget-user-buttons:after, .primary-widget-area .widget-user-profile:after, .secondary-widget-area .widget-user-profile:after {
  content: '';
  display: table;
  clear: both;
}

/* =============================================================================
	Structural Styles, Media Queries & Page Styling
/* ========================================================================== */

#page {
  min-width: 320px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  max-width: 1170px;
}
@media (max-width: 1229px) {
  .row {
    max-width: 960px;
  }
}
@media (max-width: 1023px) {
  .row {
    max-width: 738px;
  }
}

.container {
  width: 1210px;
  padding: 0 20px;
  margin: 0 auto;
}

.container:after {
  content: '';
  display: table;
  clear: both;
}

section.main {
  margin-bottom: 20px;
  flex-grow: 1;
}
@media (max-width: 641px) {
  section.main {
    margin-bottom: 0;
  }
}

.error404 section.main {
  text-align: center;
}

.error404 section.main ul {
  text-align: left;
}

/* =============================================================================
	Layout
/* ========================================================================== */

header.main {
  background: #fff;
  margin-bottom: 20px;
}
@media (max-width: 641px) {
  header.main {
    margin-bottom: 0;
  }
}

.search-bar {
  background: #e21f3d;
  height: 60px;
}
@media (max-width: 641px) {
  .search-bar {
    display: none;
  }
}

.search-form.mobile-clone form, .search-bar form {
  margin: 10px 0;
  position: relative;
}

.search-form.mobile-clone input[type=text], .search-bar input[type=text] {
  background: #c61b35;
  color: #fff;
  border: 0;
  box-shadow: none;
  margin: 0;
  height: 40px;
  font-size: 11px;
  /* line-height: 40px;*/
  line-height: normal;
  padding: 0 39px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.search-form.mobile-clone input[type=submit], .search-bar input[type=submit] {
  width: 24px;
  height: 40px;
  background: url("/Assets/Images/search.svg") no-repeat center;
  background-size: 100% auto;
  border: 0;
  outline: 0;
  position: absolute;
  top: 0;
  left: 7px;
  text-indent: -99999px;
  padding: 0;
}

.search-form.mobile-clone {
  margin: 0 0 10px;
  width: 100%;
  float: none;
}

.languages {
  padding-top: 22px;
}

.languages a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.4px;
  position: relative;
  margin-left: 17px;
}

.languages a:before {
  content: '';
  width: 1px;
  height: 25px;
  background: #ca1b36;
  position: absolute;
  left: -10px;
  top: -3px;
}

.languages a:first-child:before {
  display: none;
}

.header-bar {
  height: 96px;
}
@media (max-width: 1023px) {
  .nav_row {
    padding: 0 10px;
  }
}

.logo {
  margin: 25px 1px 0;
}
.logo img {
  width: 220px;
  height: 43px;
}
@media (min-width: 642px) {
  .main-nav {
    display: block;
  }
}
@media (max-width: 641px) {
  .main-nav {
    display: none;
    position: absolute;
    left: 0;
    top: 96px;
    background: #fff;
    padding: 0 0 25px;
    z-index: 1;
    width: 100%;
    border-bottom: 1px solid #ececec;
    box-shadow: 0 7px 10px -6px rgba(0, 0, 0, 0.3);
  }
}

.main-nav ul {
  list-style: none;
  margin: 0;
}
@media (max-width: 641px) {
  .main-nav ul.menu {
    padding: 0 15px;
  }
}

.main-nav li.menu-item {
  float: left;
  margin-left: 30px;
}
@media (max-width: 1023px) {
  .main-nav li.menu-item {
    margin-left: 22px;
  }
}
@media (max-width: 641px) {
  .main-nav li.menu-item {
    float: none;
    margin: 0 0 7px;
    border: 1px solid #f1f1f1;
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }
}
@media (max-width: 641px) {
  .main-nav li.menu-item[class*=current] {
    background: #f8f7f7;
    border-color: #f8f7f7;
  }
}

.main-nav li.menu-item[class*=current] a {
  color: #e21f3d;
  border-bottom-color: #e21f3d;
}

.main-nav li.menu-item:first-child {
  margin-left: 0;
}

.main-nav li.menu-item a {
  text-decoration: none;
  color: #7b7b7b;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.3px;
  display: block;
  padding: 38px 4px 34px;
  border-bottom: 2px solid transparent;
}
@media (max-width: 641px) {
  .main-nav li.menu-item a {
    border: 0;
    padding: 8px 13px;
    font-size: 12px;
    line-height: 20px;
  }
}

.main-nav li.menu-item a:hover {
  color: #e21f3d;
  border-bottom-color: #e21f3d;
}

@media (max-width: 1023px) {
  .main-hamburger a {
    cursor: pointer;
  }
}

footer.main {
  background: #f3f3f3;
  min-height: 300px;
}


.footnote {
  margin: 13px 1px 0;
}

.footnote img {
  float: left;
  margin-right: 13px;
}

.footnote-text {
  
  float: left;
  margin:  0;
  
}
.footnote-text p{
   font-weight:bold;
   font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.3px;
  text-align:left;
}
.footer-widgets .widget {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 13px;
  line-height: 30px;
  color: #7b7b7b;
}
@media (max-width: 1023px) {
  .footer-widgets .widget {
    font-size: 12px;
    line-height: 25px;
    padding: 0 10px;
  }
}
@media (max-width: 641px) {
  .footer-widgets .widget {
    margin-bottom: 0;
  }
  .footer-widgets .widget:last-child {
    margin-bottom: 25px;
  }
}

.footer-widgets .widget > div {
  padding: 0 15px;
}
@media (max-width: 1023px) {
  .footer-widgets .widget > div {
    padding: 0 5px;
  }
}

.footer-widgets .widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: -0.5px;
}
@media (max-width: 1023px) {
  .footer-widgets .widget ul {
    font-size: 12px;
    line-height: 25px;
  }
}

.footer-widgets .widget li > a {
  text-decoration: none;
  color: #7b7b7b;
}

.footer-widgets .widget-title {
  background: #e2e2e2;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  color: #7b7b7b;
  margin: 0 0 9px;
  padding: 6px 15px 7px;
}

.secondary-widget-area {
    background: #fff;
}
main.content {
    border-right: 1px solid #ececec;
    background: #fff;
}
@media (max-width: 641px) {

  main.content {
    border: 0;
    min-height: 0 !important;
  }
}



main.content.full-width {
  border: 0;
  width: 100% !important;
}



.posts-filter {
  border-bottom: 1px solid #e5e5e5;
  margin-right: -0.9375rem;
  /*padding: 16px 21px 16px;*/
  padding: 4px 21px 0px;
  line-height: 17px;
}
@media (max-width: 1023px) {

  .posts-filter {
    margin-right: 0;
  }
}

.posts-filter a {
  text-decoration: none;
  font-size: 12px;
  line-height: 12px;
  color: #000;
  margin-right: 19px;
  padding: 16px 0 15px;
}



.posts-filter a:hover, .posts-filter a.active {
  font-weight: 600;
  color: #d72141;
  border-bottom: 2px solid #d72141;
}


.posts-filter a:hover {
  font-weight: 400;
}


.posts-filter a:hover.active {
  font-weight: 600;
}



.posts-filter .filter-icon img {
  float: right;
  margin: 3px 0 0 10px;
}
@media (max-width: 641px) {

  .posts-filter-list {

  }

  .posts-filter-list a {
    padding: 0;
    margin: 0 0 10px;
    display: block;
    border: 0 !important;
  }

  .posts-filter-list a:last-child {
    margin-bottom: 0;
  }

  .posts-filter-list.show {
    height: auto;
  }
}



/*section.posts {
  margin-right: -0.9375rem;
}
@media (max-width: 1023px) {

  section.posts {
    margin-right: 0;
  }
}*/



.post header {
  padding: 20px;
  color: #283646;
}


.post .avatar {
  float: left;
  margin-right: 5px;
}

.post .author {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.3px;
  margin: 8px 0 6px;
}

.post .author a {
  text-decoration: none;
  color: #283646;
}

.post .date {
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.1px;
  margin: 0;
}

.post > figure {
  margin: 0px 0px 20px 0px;
  position: relative;
}

.post .image-title {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 56px 21px 18px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: -0.3px;
  text-align: right;
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, rgba(0, 0, 0, 0.4)));
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
  /*filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000', GradientType=0);*/
}

.post footer {
  padding: 0 21px 23px;
  margin-top: -2px;
}
@media (max-width: 641px) {
  .post footer {
    padding: 0 15px 15px;
  }
}

.post footer .button {
  font-size: 13px;
  line-height: 2.75rem !important;
  font-weight: 600;
  color: #e21f3d;
  background: #f8f8f8;
  margin: 0;
  padding: 0 1rem;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-family: 'open-sans', Helvetica, Arial, sans-serif !important;
}
.post footer .button:hover {
  background-color: #e21f3d;
  color: #fff;
}
.post footer .button:focus {
  outline: 1px dotted grey;
}

.post footer .button:after {
  content: '';
  width: 14px;
  height: 14px;
  vertical-align: text-top;
  display: inline-block;
  margin: 2px 0 0 13px;
  background: url("/Assets/Images/arr-btn-down.svg") no-repeat;
  background-size: 100% auto;
  transform: rotate(-90deg);
}
.post footer .button:hover:after {
  background: url("/Assets/Images/arr-btn-down-white.svg") no-repeat;
}

.post footer .post-meta {
  margin: 12px 0 0;
}
@media (max-width: 641px) {
  .post footer .post-meta {
    float: none !important;
    margin: 14px 0;
  }
}

.post footer .post-meta img {
  width: 23px;
  height: 23px;
  margin-right: 10px;
}

.post footer .post-meta a, .post footer .post-meta > span {
  text-decoration: none;
  margin-left: 21px;
  color: #e21f3d;
  position: relative;
  cursor: pointer;
}
@media (max-width: 641px) {
  .post footer .post-meta a, .post footer .post-meta > span {
    margin: 0 20px 0 0;
  }
}


/* hover fix */

.post footer .post-meta a:hover .share-window-wrap, .post footer .post-meta > span:hover .share-window-wrap, .post footer .post-meta a .share-window-wrap:hover {

}



.post footer .post-meta a {
  /*
  //Z Index
  z-index: 1;*/
}

.post footer .post-meta .quantity {
  color: #0a3250;
}

.share-window-wrap {
  position: absolute;
  left: -33px;
  top: -111px;
  width: 264px;
  height: 125px;
  display: none;
}

.share-window {
  position: relative;
  border: 1px solid #d9d9d9;
  background: #fff;
  width: 264px;
  height: 94px;
  padding: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.share-window:before {
  content: '';
  width: 16px;
  height: 8px;
  background: url("/Assets/Images/window-arrow.png") no-repeat;
  position: absolute;
  bottom: -8px;
  left: 35px;
}


.share-window .share-input {
  position: relative;
  margin-bottom: 10px;
}


.share-window .share-input input {
  width: 100%;
  background: #fff;
  height: 30px;
  box-shadow: none;
  padding: 5px;
  font-size: 11px;
  line-height: 11px;
  color: #a0a0a0;
  margin: 0;
  border: 1px solid #dbdbdb;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}


.share-window .share-input button {
  position: absolute;
  top: 2px;
  right: 3px;
  height: 26px;
  width: 26px;
  background: #fff url("/Assets/Images/icon-url.png") no-repeat center;
  border-left: 1px solid #dbdbdb;
  text-indent: -99999px;
  padding: 0;
  margin: 0;
}


.share-window > a, .share_post a {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  color: #fff !important;
  padding: 10px;
  text-align: center;
  float: left;
  min-width: 99px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}


.share-window .share-facebook, .share_post .share-facebook {
  background: #3b5998 url("/Assets/Images/share-facebook.svg") no-repeat 8px 8px;
  background-size: 14px auto;
  padding-left: 29px;
  margin: 0 10px 0 0 !important;
  text-decoration: none;
}


.share-window .share-twitter, .share_post .share-twitter {
  background: #55acee url("/Assets/Images/share-twitter.svg") no-repeat 15px 7px;
  background-size: 16px auto;
  padding-left: 29px;
  margin: 0 !important;
  text-decoration: none;
}


.post-hot {
  background: #fffbe9;
}


.post-hot footer .button {
  background: #fff;
}


.post-entry {
  padding: 20px 22px 0;
}
.post-entry .content-title{
    padding:0.9375rem 0;
}
@media (max-width: 641px) {

  .post-entry {
    padding: 15px 15px 0;
  }
}


.post-entry .post-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.55px;
  color: #000;
  margin: 0 0 7px;
}
@media (max-width: 641px) {

  .post-entry .post-title {
    font-size: 16px;
  }
}


.post-entry .post-title a {
  color: inherit;
  text-decoration: none;
}


.post-entry p {
  font-size: 12px;
  line-height: 21px;
  letter-spacing: -0.36px;
}
@media (max-width: 641px) {
  .post-entry p {
    line-height: 17px;
    margin-bottom: 12px;
  }
}

.add-to-fav {
  margin: 2px 1px;
}


.add-to-fav .wpfp-span {
  display: block;
  width: 34px;
  height: 34px;
  text-indent: -99999px;
}

.add-to-fav .wpfp-span a {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.add-to-fav .wpfp-span a.remove {
  border: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #f6a43f url("/Assets/Images/favorite_white.svg") no-repeat center;
  background-size: 14px auto;
}
.tweet_post .add-to-fav .wpfp-span a.remove {
  border: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff url("/Assets/Images/favorite_yellow.svg") no-repeat center;
  background-size: 14px auto;
}
.add-to-fav .wpfp-span a.add {
  background: #fff url("/Assets/Images/favorite.svg") no-repeat center;
  background-size: 14px auto;
  border: 1px solid #E0E0E0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.add-to-fav .wpfp-span img {
  display: none !important;
}


.icon-hot {
  margin: 2px 26px 0 0;
}
@media (max-width: 641px) {
  .icon-hot {
    margin-right: 10px;
  }
}


.icon-hot img {
  float: left;
  margin-right: 8px;
  width: 34px;
  height: 34px;
}

.icon-hot span {
  float: left;
  color: #76692a;
  font-size: 12px;
  line-height: 12px;
  margin: 12px 0 0;
}
@media (max-width: 641px) {
  .icon-hot span {
    display: none;
  }
}


.primary-widget-area {
  border-right: 1px solid #ececec;
  background: #f8f8f8;
}

.primary-widget-area .widget {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}
@media (max-width: 1023px) {
  .primary-widget-area {
    border-right: 0;
  }
}
@media (max-width: 767px) {

  .primary-widget-area {
    border-top: 1px solid #ececec;
  }
}
@media (max-width: 1023px) {

  .secondary-widget-area {
  }
}


.secondary-widget-area .widget {
  margin-left: -0.9375rem;
}
.secondary-widget-area img {
  max-width: 100%;
}

.secondary-widget-area.mobile-clone {
  min-height: 0 !important;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 156px;
  display: none;
  z-index: 2;
  box-shadow: 0 7px 10px -6px rgba(0, 0, 0, 0.3);
}
@media (max-width: 641px) {
  .secondary-widget-area.mobile-clone {
    position: static;
    display: block !important;
    box-shadow: none;
  }
}


.secondary-widget-area.mobile-clone > div {
  width: 100%;
  max-width: 738px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.secondary-widget-area.mobile-clone .widget-user-buttons {
  padding-left: 15px;
  padding-right: 15px;
}

.secondary-widget-area.mobile-clone .image-only .textwidget img {
  width: 100%;
}
@media (max-width: 641px) {
  .primary-widget-area, .secondary-widget-area {
    min-height: 0 !important;
    display: none;
  }
}

.primary-widget-area .widget, .secondary-widget-area .widget {
  border-bottom: 1px solid #e5e5e5;
  color: #283646;
  padding-bottom: 10px;
}
@media (max-width: 1023px) {
  .primary-widget-area .widget, .secondary-widget-area .widget {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.primary-widget-area .widget:last-child, .secondary-widget-area .widget:last-child {
  border-bottom: 0;
}

.primary-widget-area .widget .widget-title, .secondary-widget-area .widget .widget-title {
  margin: 0;
  line-height: 16px;
  color: #283646;
  padding: 16px 18px 17px;
  border-bottom: 1px solid transparent;
  font-family: "museo-slab",serif;
font-weight: 500;
font-size:18px;
}
@media (max-width: 1229px) {
  .primary-widget-area .widget .widget-title, .secondary-widget-area .widget .widget-title {
    padding: 16px 15px 17px;
    font-size:15px;
  }
}

.primary-widget-area .widget ul, .secondary-widget-area .widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.primary-widget-area .widget ul li, .secondary-widget-area .widget ul li {
  font-size: 12px;
  line-height: 17px;
}

.primary-widget-area .widget ul li > a:first-child, .secondary-widget-area .widget ul li > a:first-child {
  display: block;
  padding: 7px 19px;
  color: #283646;
  text-decoration: none;
  position: relative;
}
.widget ul li[class*=current] .children li a {
  color: #283646;
}
@media (max-width: 1229px) {
  .primary-widget-area .widget ul li > a:first-child, .secondary-widget-area .widget ul li > a:first-child {
    padding: 7px 15px;
  }
}

.primary-widget-area .widget ul li > a:first-child:hover, .primary-widget-area .widget ul li[class*=current] a, .secondary-widget-area .widget ul li > a:first-child:hover, .secondary-widget-area .widget ul li[class*=current] a {
  color: #e21f3d;
  font-weight: 500;
}

.primary-widget-area .widget ul li > a:first-child:hover:before, .primary-widget-area .widget ul li[class*=current] a:before, .secondary-widget-area .widget ul li > a:first-child:hover:before, .secondary-widget-area .widget ul li[class*=current] a:before {
  content: '';
  position: absolute;
  left: -1px;
  top: -2px;
  width: 2px;
  height: calc(100% + 2px);
  background: #e21f3d;
}

.primary-widget-area .widget .recentcomments, .secondary-widget-area .widget .recentcomments {
  padding: 7px 19px;
}
@media (max-width: 1229px) {
  .primary-widget-area .widget .recentcomments, .secondary-widget-area .widget .recentcomments {
    padding: 7px 15px;
  }
}

.primary-widget-area .widget_nav_menu, .secondary-widget-area .widget_nav_menu {
  background: #fff;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}


.primary-widget-area .widget_nav_menu .widget-title, .secondary-widget-area .widget_nav_menu .widget-title {
  border-bottom-color: #e5e5e5;
}


.primary-widget-area .widget_nav_menu ul, .secondary-widget-area .widget_nav_menu ul {
  margin: 2px 0 0;
}


.primary-widget-area .widget_tag_cloud, .secondary-widget-area .widget_tag_cloud {
  padding-bottom: 17px;
}


.primary-widget-area .widget_tag_cloud .widget-title:before, .secondary-widget-area .widget_tag_cloud .widget-title:before {
  content: '';
  width: 16px;
  height: 14px;
  background: url("/Assets/Images/tag.svg") no-repeat center;
  background-size: 100% auto;
  margin: 2px 7px 0 1px;
  float: left;
}


.primary-widget-area .tagcloud, .secondary-widget-area .tagcloud {
  padding: 0 15px;
}
@media (max-width: 1229px) {
  .primary-widget-area .tagcloud, .secondary-widget-area .tagcloud {
    padding: 0 7px;
  }
}

.primary-widget-area .tagcloud a, .secondary-widget-area .tagcloud a {
  color: #202020;
  font-size: 11px !important;
  line-height: 11px;
  background: #f8ecdf;
  text-decoration: none;
  padding: 6px 9px 7px;
  margin: 0 3px 6px;
  float: left;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
@media (max-width: 1229px) {
  .primary-widget-area .tagcloud a, .secondary-widget-area .tagcloud a {
    padding: 6px 6px 7px;
  }
}

.primary-widget-area .tagcloud a:before, .secondary-widget-area .tagcloud a:before {
  content: '#';
}

.primary-widget-area .tagcloud a:hover, .secondary-widget-area .tagcloud a:hover {
  color: #fff;
  background: #7d7d7d;
}


.primary-widget-area .widget_text .widget-title, .secondary-widget-area .widget_text .widget-title {

}
@media (max-width: 1229px) {
  .primary-widget-area .widget_text .widget-title, .secondary-widget-area .widget_text .widget-title {
  }
}

.primary-widget-area .textwidget, .secondary-widget-area .textwidget {
  padding: 0 23px;
  font-size: 11px;
  line-height: 16px;
}
@media (max-width: 1229px) {
  .primary-widget-area .textwidget, .secondary-widget-area .textwidget {
    padding: 0 15px;
  }
}

.primary-widget-area .textwidget p, .secondary-widget-area .textwidget p {
  font-size: 11px !important;
  line-height: 16px !important;
}

.primary-widget-area .textwidget form, .secondary-widget-area .textwidget form {
  margin: 17px -4px 2px;
}

.primary-widget-area .image-only .widget-title, .secondary-widget-area .image-only .widget-title {
  padding: 16px 19px;
}
@media (max-width: 1229px) {
  .primary-widget-area .image-only .widget-title, .secondary-widget-area .image-only .widget-title {
    padding: 16px 15px;
  }
}

.primary-widget-area .image-only .textwidget, .secondary-widget-area .image-only .textwidget {
  padding: 0;
}
@media (max-width: 767px) {
  .primary-widget-area .image-only .textwidget img, .secondary-widget-area .image-only .textwidget img {
    width: 100%;
  }
}

.primary-widget-area .widget_contact .textwidget, .secondary-widget-area .widget_contact .textwidget {
  padding: 0 20px;
}
@media (max-width: 1229px) {
  .primary-widget-area .widget_contact .textwidget, .secondary-widget-area .widget_contact .textwidget {
    padding: 0 15px;
  }
}

.primary-widget-area .contact-list, .secondary-widget-area .contact-list {
  margin: 15px 18px 0;
}
@media (max-width: 1229px) {
  .primary-widget-area .contact-list, .secondary-widget-area .contact-list {
    margin: 15px 15px 0;
  }
}

.primary-widget-area .contact-person, .secondary-widget-area .contact-person {
  margin-bottom: 18px;
}

.primary-widget-area .contact-person > strong, .secondary-widget-area .contact-person > strong {
  display: block;
  margin-bottom: 8px;
}

.primary-widget-area .contact-person > img, .secondary-widget-area .contact-person > img {
  float: left;
  margin: 0 6px 6px 0;
}

.primary-widget-area .contact-person:last-child, .secondary-widget-area .contact-person:last-child {
  margin-bottom: 6px;
}

.primary-widget-area .contact-person-desc, .secondary-widget-area .contact-person-desc {
  float: left;
  margin: -4px 0 0;
}

.primary-widget-area .contact-person-desc p, .secondary-widget-area .contact-person-desc p {
  font-size: 11px;
  line-height: 16px;
  margin: 0;
}

.primary-widget-area .contact-person-desc p strong, .secondary-widget-area .contact-person-desc p strong {
  font-weight: 600;
}

.primary-widget-area .widget_instagram, .secondary-widget-area .widget_instagram {
  border-bottom: 0;
  padding-bottom: 0;
}

.primary-widget-area .widget_instagram .widget-title, .secondary-widget-area .widget_instagram .widget-title, .null-instagram-feed .widget-title {
  padding-top: 15px;
  padding-bottom: 14px;
}

.instagram_widget .widget-title:before {
  float: left;
  content: "\f16d";
  font-family: FontAwesome;
  font-size: 20px;
  margin-right: 5px;
}

.contact_widget .widget-title:before, .widget_contact .widget-title:before{
  float: left;
  content: "\f0e6";
  font-family: FontAwesome;
  font-size: 20px;
  margin-right: 5px;
}

.contact_widget b, .widget_contact b {color: #e21f3d;
    font-size: 12px;
    display: block;
    margin-bottom: 14px;}

.users_widget .widget-title:before {
  float: left;
  content: "\f0c0";
  font-family: FontAwesome;
  font-size: 20px;
  margin-right: 5px;
}

.primary-widget-area .widget_instagram a, .secondary-widget-area .widget_instagram a {
  color: #e21f3d;
  text-decoration: none;
}

.primary-widget-area .widget_instagram .widget-image, .secondary-widget-area .widget_instagram .widget-image {
  text-align: center;
}
@media (max-width: 767px) {
  .primary-widget-area .widget_instagram .widget-image, .secondary-widget-area .widget_instagram .widget-image {
    padding: 0 15px;
  }
  .primary-widget-area .widget_instagram .widget-image img, .secondary-widget-area .widget_instagram .widget-image img {
    width: 100%;
    height: auto;
  }
}

.primary-widget-area .widget-user-buttons, .secondary-widget-area .widget-user-buttons {
  padding: 7px;
  border-bottom: 1px solid #e5e5e5;
}

.primary-widget-area .widget-user-buttons a, .secondary-widget-area .widget-user-buttons a {
  float: left;
  width: 49%;
  background: #283646;
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 11px;
  padding: 11px 10px 13px;
  text-decoration: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.primary-widget-area .widget-user-buttons .login, .secondary-widget-area .widget-user-buttons .login {
  background: #dce8e9;
  color: #283646;
  margin-left: 2%;
}

.primary-widget-area .widget-user-profile, .secondary-widget-area .widget-user-profile {
  padding: 20px 20px 7px;
}
@media (max-width: 1229px) {
  .primary-widget-area .widget-user-profile, .secondary-widget-area .widget-user-profile {
    padding: 15px 15px 2px;
  }
}

.primary-widget-area .widget-user-profile figure, .secondary-widget-area .widget-user-profile figure {
  float: left;
  margin: 0 19px 0 0;
}

.primary-widget-area .widget-user-profile .user-info, .secondary-widget-area .widget-user-profile .user-info {
  margin: 4px 0 0;
  line-height: 21px;
}
@media (max-width: 1229px) {
  .primary-widget-area .widget-user-profile .user-info, .secondary-widget-area .widget-user-profile .user-info {
    margin: 0;
  }
}

.primary-widget-area .widget-user-profile p, .secondary-widget-area .widget-user-profile p {
  font-size: 11px;
  margin: 0;
}

.primary-widget-area .wpfp_users_favorites_widget_view .widget-title, .secondary-widget-area .wpfp_users_favorites_widget_view .widget-title {
  padding-top: 16px;
  padding-bottom: 15px;
  line-height: 17px;
}

.primary-widget-area .wpfp_users_favorites_widget_view .widget-title:before, .secondary-widget-area .wpfp_users_favorites_widget_view .widget-title:before {
  content: '';
  float: left;
  width: 15px;
  height: 15px;
  margin: 0 8px 0 1px;
  background: url("/Assets/Images/favorite.svg") no-repeat;
  background-size: 100% auto;
}

.primary-widget-area .favorites-list, .secondary-widget-area .favorites-list {
  max-height: 195px;
  overflow-y: auto;
  border-top: 1px solid #e5e5e5;
}

.primary-widget-area .favorites-list li, .secondary-widget-area .favorites-list li {
  padding: 11px 19px 13px;
  width: 100%;
  margin: 0;
}
@media (max-width: 1229px) {
  .primary-widget-area .favorites-list li, .secondary-widget-area .favorites-list li {
    padding: 11px 15px 13px;
  }
}

.primary-widget-area .favorites-list li a, .secondary-widget-area .favorites-list li a {
  color: #494949 !important;
  padding: 0 !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  margin-bottom: 3px;
}

.primary-widget-area .favorites-list li a:hover, .secondary-widget-area .favorites-list li a:hover {
  font-weight: 600 !important;
}

.primary-widget-area .favorites-list li a:hover:before, .secondary-widget-area .favorites-list li a:hover:before {
  display: none;
}

.primary-widget-area .favorites-list li p, .secondary-widget-area .favorites-list li p {
  color: #a9a9a9;
  font-size: 11px;
  line-height: 11px;
  margin: 0;
}

.primary-widget-area .favorites-list li:nth-child(odd), .secondary-widget-area .favorites-list li:nth-child(odd) {
  background: #f8f8f8;
}

.primary-widget-area .widget-favorites-footer, .secondary-widget-area .widget-favorites-footer {
  padding: 6px 20px 0;
}
@media (max-width: 1229px) {
  .primary-widget-area .widget-favorites-footer, .secondary-widget-area .widget-favorites-footer {
    padding: 6px 14px 0;
  }
}

.primary-widget-area .widget-favorites-footer .button, .secondary-widget-area .widget-favorites-footer .button {
  display: block;
  background: #8f8f8f;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  line-height: 11px;
  padding: 11px 10px 13px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-top:10px !important;
}

.primary-widget-area .widget-favorites-footer a:not(.button), .secondary-widget-area .widget-favorites-footer a:not(.button) {
  text-decoration: none;
  color: #494949;
  font-size: 11px;
  line-height: 11px;
  padding: 0;
}

.primary-widget-area .widget-favorites-footer a:not(.button):after, .secondary-widget-area .widget-favorites-footer a:not(.button):after {
  content: '';
  width: 11px;
  height: 11px;
  display: inline-block;
  background: url("/Assets/Images/trash.svg") no-repeat;
  background-size: 100% auto;
  margin: 0 0 -1px 10px;
}

.primary-widget-area input, .secondary-widget-area input {
  height: 35px;
  font-size: 11px;
  /*line-height: 33px;*/
  line-height: normal;
  color: #4f4f4f;
  padding: 0 10px;
  box-shadow: none;
  border-color: #e1e1e1;
  margin: 5px 0;
  background: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.primary-widget-area textarea {
  min-height: 50px;
  font-size: 11px;
  line-height: normal;
  color: #4f4f4f;
  padding: 10 0px;
  box-shadow: none;
  border-color: #e1e1e1;
  margin: 10px 0;
  background: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #4f4f4f;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #4f4f4f;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #4f4f4f;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #4f4f4f;
}

.primary-widget-area input::-webkit-input-placeholder, .secondary-widget-area input::-webkit-input-placeholder {
  color: #4f4f4f;
  opacity: 0.9;
}

.primary-widget-area input:-moz-placeholder, .secondary-widget-area input:-moz-placeholder {
  color: #4f4f4f;
  opacity: 0.9;
}

.primary-widget-area input::-moz-placeholder, .secondary-widget-area input::-moz-placeholder {
  color: #4f4f4f;
  opacity: 0.9;
}

.primary-widget-area input:-ms-input-placeholder, .secondary-widget-area input:-ms-input-placeholder {
  color: #4f4f4f;
  opacity: 0.9;
}

.primary-widget-area input[type=submit], .primary-widget-area input[type=submit]:focus, .secondary-widget-area input[type=submit], .secondary-widget-area input[type=submit]:focus {
  border: 0;
  width: 100%;
  background: #e21f3d;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  line-height: 35px;
}

.primary-widget-area input:focus, .secondary-widget-area input:focus {
  border-color: #e1e1e1;
  background: #fff;
}

.widget-text-list > li {
  padding-left: 22px;
  position: relative;
}

.widget-text-list > li img {
  position: absolute;
  left: 0;
  top: 9px;
}

.content-title {
  padding: 0.9375rem;
}

.content-title h1, .content-title h2 {
  margin: 0;
  font-size: 2rem;
  line-height: 30px;
  color: #283646;
}

.page-404 {
  background: #fff;
  padding: 20px 40px;
}

.page-404 h1 {
  color: #e21f3d;
}

.page-404 h2 {
  font-size: 24px;
}

.single-content {
  padding: 0 0 50px 0.9375rem;
}

.single-page-content {
  padding: 0.9375rem;
}


.single-page-content p {
  font-size: 16px;
  line-height: 20px;
}

.comments-area {
  padding: 0 0 0.9375rem 0.9375rem;
}

.comments-area input[type=submit] {
  border: 0;
  width: 100%;
  background: #e21f3d;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  line-height: 35px;
}

blockquote {
  font-style: italic;
  padding-top: 0;
}

article iframe {
  max-width: 100%;
}

.alignnone {
  margin: 0 !important;
  display: inline !important;
}

.aligncenter, div.aligncenter {
  display: block;
  margin: 20px auto !important;
}

.alignright {
  float: right;
  margin: 5px 0 7px 15px !important;
}

.alignleft {
  float: left;
  margin: 5px 15px 7px 0 !important;
}

.aligncenter {
  display: block;
  margin: 20px auto !important;
}

a img.alignright {
  float: right;
  margin: 5px 0 7px 15px;
}

a img.alignnone {
  margin: 0;
}

a img.alignleft {
  float: left;
  margin: 5px 15px 7px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  max-width: 96%;
  margin-bottom: 10px;
}

.wp-caption.alignnone {
  margin: 0 0 10px !important;
  display: inline-block !important;
}

.wp-caption.alignleft {
  margin: 5px 15px 10px 0;
}

.wp-caption.alignright {
  margin: 5px 0 10px 15px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  font-style: italic;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

.tablet-detect {
  display: none;
}
@media (max-width: 1023px) {
  .tablet-detect {
    display: block;
  }
}

.mobile-detect {
  display: none;
}
@media (max-width: 641px) {
  .mobile-detect {
    display: block;
  }
}
@media (max-width: 641px) {
  .mobile-hide {
    display: none;
  }
}

/***--[ More Styles ]--********************************/

.nav_row {
  position: relative;
}
.big_menu {
  position: absolute;
  width: 100%;
  z-index: 9;
  background: #ffffff;
  top: 70px;
  left: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: none;
  padding: 20px 5px;
  overflow: hidden;
}
.main-hamburger a {
  background-color: #ffffff;
  /* background: url("images/hamburger.svg") no-repeat center; */
  background-size: 100% auto;
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 21px -2px 0px 28px;
  text-indent: -99999px;
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 99;
  padding-right: 10px;
  background-size: 15px auto;
  overflow: hidden;
}
.main-hamburger.big_menu_active a:before {
  content: "";
  height: 45px;
  width: 45px;
  /* background: red; */
  z-index: 999;
  position: absolute;
  top: 5px;
  left: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.main-hamburger a img {
  display: block;
  width: 15px;
  height: 20px;
  position: relative;
  left: 18px;
  top: 18px;
}
.big_menu .columns ul {
  list-style: none;
  margin: 0px;
  padding-left: 15px;
}
.big_menu .columns ul li {
  padding: 5px 0px;
}
.big_menu .columns ul li a {
  font-size: 12px;
  color: #7b7b7b;
  line-height: 14px;
  display: block;
}
.big_menu .columns ul li a br {
  line-height: 4px;
}
.big_menu .columns ul li a:hover {
  color: #d72141;
}

/*.big_menu .columns span.ul_head, .big_menu .columns .widget-title {background: rgb(247, 247, 247); display: block; font-size: 12px; font-weight: 700; color: #283646; padding: 10px 20px; margin-bottom: 15px;}*/

.big_menu .columns .widget-title {
  display: none;
}
.big_menu .columns .menu {
  padding-left: 0px !important;
}
.big_menu .columns .menu > li:first-child > a:first-child {
  background: rgb(247, 247, 247);
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #283646;
  padding: 10px 20px;
  margin-bottom: 15px;
  text-decoration: none;
}

/* For tabs */

.posts-filter-list ul.tabs {
  list-style: none;
}
.posts-filter-list ul.tabs li {
  background: none;
  display: inline-block;
  padding: 10px 14px;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  line-height: 12px;
  color: #000;
  margin-right: 19px;
  padding: 16px 0 15px;
}
.posts-filter-list ul.tabs li:hover {
  color: #d72141;
  border-bottom: 2px solid #d72141;
}
.posts-filter-list ul.tabs li.current {
  font-weight: 600;
  color: #d72141;
  border-bottom: 2px solid #d72141;
}
.tab-content {
  display: none;
  /*background: #ededed;
      padding: 15px;*/
}
.tab-content.current {
  display: inherit;
}
.filter-icon {
  margin-top: 14px;
}
.post-header-image {
  margin-right: -0.9375rem;
}
.widget_tag_cloud .textwidget {
  padding: 0px;
}

/***--[ Pagination ]--********************************/

.pagination {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #e1e1e1;
  margin-right: -15px;
  position: relative;
}
.pagination .screen-reader-text {
  display: none;
}
.pagination .nav-links {
  text-align: center;
}
.pagination .nav-links .page-numbers {
  color: #E41638;
  text-decoration: none;
  padding: 5px 11px;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  font-weight: bold;
  margin-right: 7px;
}
.pagination .nav-links .page-numbers:hover, .pagination .nav-links .current.page-numbers {
  color: #ffffff;
  border: 1px solid #E41638;
  background: #E41638;
}
.pagination .nav-links .next {
  position: absolute;
  right: 10px;
  top: 20px;
}
.pagination .nav-links .prev {
  position: absolute;
  left: 10px;
  top: 20px;
}


/***--[ Single ]--********************************/

.single-content header {
  padding: 20px 0px 20px 0px;
}
.single-post .content-title {}

/*.single-content p:nth-child(1) {font-weight: bold; line-height: 20px;}*/

.member_menu, .thinking_menu, .knowmore_menu, .hero_step2 {
  display: none;
}

/***--[ Comments ]--********************************/

.clearfix {
  clear: both;
}
#comments {
  padding-top: 20px;
}
#comments ul {
  list-style: none;
}
.comments-title, #reply-title {
  display: none;
}
.comments-list {
  list-style: none;
  margin: 0px;
}
.comment-author {
  padding: 0px;
  border: none;
}
.comment-author .fn {
  margin-left: 15px;
}
.comments-list .comment {
  background: rgb(248, 248, 248);
  padding: 20px;
}
.comments-list .comment.even {
  background: #f8ecdf;
}
.comment-metadata {
  display: inherit;
  float: right;
  font-size: 12px;
}
.comment-metadata a {
  color: #000000;
  text-decoration: none;
}
.comment-metadata .edit-link a {
  color: #d72141;
  padding-left: 15px;
}
.comment-reply-link {
  text-align: right;
  padding: 0px 0px 10px;
  display: block;
  font-size: 14px;
  text-decoration: none;
}

/***--[ User Pro ]--********************************/

.profile_card {
  margin-left: -0.9375rem;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  color: #283646;
  font-weight: bold;
}
.profile_card div.userpro {
  border: none;
  padding: 0px !important;
  margin: 0px !important;
}
.profile_card .userpro-card-img {
  float: left;
  margin-right: 19px;
}
.profile_card .userpro-card-info .userpro-card-right {
  display: none;
}
.setting_login_links {
  margin-left: -0.9375rem;
  padding: 7px;
  border-bottom: 1px solid #e5e5e5;
  height: 50px;
}
a.setting, a.login_logout {
  float: left;
  width: 49%;
  background: #283646;
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 11px;
  padding: 11px 10px 13px;
  text-decoration: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
a.login_logout {
  background: #dce8e9;
  color: #283646;
  margin-left: 2%;
}
.edit_profile_popup .userpro-centered, .edit_profile_popup .userpro-alert {
  display: none;
}
.div.userpro-pic-profilepicture img {
  border-radius: 0px;
}
div.userpro-card-left, div.userpro-card-left a {
  color: #444 !important;
  font-size: 12px !important;
  font-weight: bold;
  color: #283646 !important;
}
span.up-first_name, span.up-last_name {
  font-size: 12px !important;
  font-weight: bold;
  color: #283646 !important;
}
span.up-profilepicture img {
  width: 52px;
  margin-right: 15px;
  border-radius: 2px;
}

/***--[ map ]--********************************/

#map-canvas {
  height: 370px;
}
.sidebar_map .textwidget {
  padding: 0px;
}

/***--[ Instagram ]--********************************/

.instagram-pics a {
  padding: 0px !important;
}
.instagram-pics a:hover:before {
  display: none !important;
}
.null-instagram-feed {
  padding: 0px !important;
}
.null-instagram-feed .widget-title a {
  text-decoration: none;
}

/***--[ Ads ]--********************************/

.sidebar_ads {
  margin-left: -0.9375rem;
}
.sidebar_ads a {
  margin-bottom: 10px;
  margin-top: 10px;
  display: block;
}
.sidedar_ads a img {
  width: 100%;
}

/***--[ Temp Twitter ]--********************************/

blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  padding: 0 16px 16px 16px;
  max-width: 468px;
}
blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}
blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}
blockquote.twitter-tweet a:hover, blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}
#twitter-widget-0 {
  width: 100% !important;
}
.Tweet-header, .Tweet-metadata, .Tweet-actions, .MediaCard-media {
  display: none !important;
}

/* Twitter */

.tweet_post {
  background: #55ACEE;
  color: #ffffff;
  padding-bottom: 30px;
}
.tweet_post .post-title {
  display: none;
}
.tweet_post footer .button {
  display: none;
}
.tweet_post p {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
}
.tweet_post a {
  color: #ffffff !important;
}
.tweet_post footer .post-meta span {
  color: #ffffff;
}
.no_fav {
  padding: 20px;
}

/***--[ USer Pro Forms ]--********************************/

.edit_profile_popup {
  padding: 15px 35px;
}
div.userpro-input input[type=text], div.userpro-input input[type=password], div.userpro-input textarea {
  box-shadow: none !important;
  height: 36px !important;
}
div.userpro-input input[type=text]:focus, div.userpro-input input[type=password]:focus, div.userpro-input textarea:focus {
  box-shadow: none !important;
}
div.userpro-label label {
  font-weight: bold !important;
}
div.userpro-pic {
  margin: 0 0 12px 0;
  float: left;
  background: #F9F9F9;
  padding: 15px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
}
a.userpro-button, div.userpro div.ajax-file-upload {
  height: 36px !important;
  line-height: 36px !important;
}
.userpro-pic-upload {
  margin-top: 30px !important;
  margin-left: 10px !important;
}
.chosen-choices {
  background: transparent !important;
  box-shadow: none;
  border-radius: 3px;
}
div.userpro-submit input[type=submit].userpro-button {
  display: block !important;
  margin: 15px auto !important;
}
.userpro-submit input.userpro-button.red {
  background: transparent !important;
  color: #E21F3D !important;
  margin: 0px !important;
  display: block !important;
  text-align: center;
  margin: 0 auto !important;
  border: none !important;
}
div.userpro-social-connect a.userpro-social-facebook {
  display: none !important;
}
.userpro .userpro-submit input[type="submit"] {
  height: 40px !important;
  background: #E21F3D !important;
  border: 0px !important;
  font-weight: bold !important;
  width: 100% !important;
  margin: 0 auto !important;
}
.userpro .userpro-submit input[type="button"] {
  border: 0px !important;
  color: #E21F3D !important;
  text-decoration: underline !important;
  font-size: 12px !important;
  width: 100% !important;
  margin: 0 auto !important;
}
#setting_loggedout .right_col {
  padding: 20px 8%;
  border-left: 1px solid #eeeeee;
}
#setting_loggedout .left_col {
  padding: 20px 8%;
}
div.userpro-social-big a.userpro-social-facebook {
  background: #3b5998;
  border-bottom: 0px !important;
  border-radius: 3px !important;
  height: 40px !important;
  font-size: 13px !important;
  font-weight: bold !important;
}
.popup_wrap {
  padding: 8%;
}

/***--[ Hover Effects ]--********************************/

button:hover, button:focus, .button:hover, .button:focus, input[type=submit]:hover, a.setting:hover, a.login_logout:hover, .post figure:hover img, .sidebar_ads a img:hover {
  opacity: 0.9;
}
.post footer span:hover, .post footer .post-meta a:hover {
  color: #681020;
}
.languages a:hover {
  text-decoration: underline;
}
.footer-widgets a:hover {
  color: #d72141;
}

/***--[ More ]--********************************/

.step2_button_wrap {
  height: 55px;
  width: 520px;
  margin: 0 auto;
}
.userpro-social-big {
  width: 243px;
  float: left;
  margin-right: 20px;
}
.step2_button_wrap .userpro-social-big a.userpro-social-facebook {
  height: 55px !important;
  margin: 0px !important;
  border-radius: 5px !important;
  padding-top: 18px;
}
.step2_button_wrap div.userpro-clear {
  display: none;
}
.reveal-modal .userpro-social-big {
  width: 100% !important;
}
.yes_scroll {
  overflow: scroll;
}
.no_scroll {
  overflow: hidden;
}
.page_content .content-title {
  position: relative;
}
.page_content .content-title h1 {
  padding-right: 30px;
}
.page_content .content-title .add-to-fav {
  position: absolute;
  right: 0px;
  top: 15px;
}
.page_content .post-header-image {
  position: relative;
}

/***--[ Responsive Fixes ]--********************************/

.menu_desktop {
  display: block;
}
.menu_tablet {
  display: none;
}
.menu_mobile {
  display: none;
}
@media (max-width: 1023px) {

 .primary-widget-area .widget .widget-title, .secondary-widget-area .widget .widget-title {
  font-size:15px;
}

  .kontaktWrapper {
    padding: 8px 11px 15px 0px !important;
  }
  .main-nav {
    display: none;
  }
  .step2_button_wrap div.userpro-social-big a.userpro-social-facebook {
    height: 46px !important;
    padding-top: 13px !important;
  }
  .pagination {
    margin-right: 0px;
  }
  .sidebar_ads, .setting_login_links {
    margin-left: 0px;
  }
  span.up-profilepicture img {
    margin-left: 15px;
  }
  .post-header-image {
    margin-right: 0px;
  }
  .menu_desktop {
    display: none;
  }
  .menu_tablet {
    display: block;
  }
  .menu_mobile {
    display: none;
  }
}
.kontaktWrapper {
  padding: 0px 15px 15px;
}
.kontaktWrapper p {
  margin: 0px;
  padding: 0px;
}
@media (max-width: 641px) {
  .kontaktWrapper {
    padding: 8px 11px 15px 0px !important;
  }
  div.userpro-social-big {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .step2_button_wrap div.userpro-social-big a.userpro-social-facebook {
    height: 55px !important;
    width: 100% !important;
    padding-top: 18px !important;
  }
  .step2_button_wrap div.userpro-clear {
    display: block;
  }
  .step2_button_wrap {
    height: 55px;
    width: 100%;
  }
  .hero_step2 {
    padding-bottom: 100px;
  }
  .share-window-wrap {
    left: 0px;
  }
  .post footer .button {
    width: 100%;
  }
  .instagram-pics a {
    padding: 0.9375rem !important;
  }
  .instagram-pics a img {
    width: 100%;
  }
  .sidebar_map {
    display: none;
  }
  .sidebar_ads {
    display: none;
  }
  .menu_desktop {
    display: none;
  }
  .menu_tablet {
    display: none;
  }
  .menu_mobile {
    display: block;
  }
  .menu_mobile ul a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.ajax_loader {
  padding-left: 15px;
  display: none;
}
.ajax_loader img {
  width: 30px;
  height: 31px;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}

/***--[ temp ]--********************************/

.pagination .nav-links .next {
  display: none;
}
.pagination .nav-links .prev {
  display: none;
}

/* Twitter post single page fix */

.single-content.tweet_post {
  padding: 0px 15px 45px 15px;
  margin-right: -15px;
}
.single-content.tweet_post .share-twitter, .single-content.tweet_post .share-facebook {
  border: 1px solid #ffffff;
}

/* for author info */

header .author span {
  color: #e21f3d;
  font-size: 10px;
  font-weight: normal;
  padding-left: 5px;
  border-left: 1px solid #e5e5e5;
  margin-left: 5px;
}

/* thumb title fix */

.title_with_thumb {
  padding-bottom: 0px;
  margin-bottom: -5px;
}

/* radio */

.logged_out_radio label {
  color: #666 !important;
  position: relative;
  display: block;
  font-size: 13px;
  font-weight: normal !important;
  cursor: pointer !important;
  line-height: 1.5;
}
.logged_out_radio label input {
  margin-right: 10px !important;
}

/***--[ Button ]--********************************/

.use_button {
  height: 25px !important;
  background: #E21F3D !important;
  border: 0px !important;
  font-weight: bold !important;
  width: 100% !important;
  margin: 0 auto !important;
  font-size: 13px !important;
  line-height: 0px !important;
  border-radius: 3px;
  color: #ffffff !important;
  position: relative !important;
  left: 0px;
  width: 100px !important;
  top: 0px;
}

/***--[ Admin bar fix ]--********************************/

@media screen and (max-width: 600px) {
  #wpadminbar {
    position: fixed;
  }
}

/***--[ Sidebar menu for pages with subpages and parent ]--********************************/

.child_parent_menu li a:hover {
  font-weight: 500 !important;
}
.child_parent_title a {
  text-decoration: none;
  color: #283646;
}
.child_parent_title a:hover {
  color: #e21f3d;
}

/***--[ Popup Fix ]--********************************/

#login_popup {
  max-width: 400px;
}
#setting_loggedout {
  max-width: 750px;
}

/***--[ Tag Filter  ]--********************************/

.filter-icon {
  position: relative;
  cursor: pointer;
}
.filter-box {
  position: absolute;
  width: 320px;
  right: -22px;
  top: 31px;
  z-index: 99999;
  background-color: #ffffff;
  padding: 20px;
  height: 385px;
  border: 1px solid #E5E5E5;
  display: none;
}
.tag_filter_apply {
  position: relative;
  bottom: -15px;
  right: 0px;
  border: 0;
  width: 100%;
  background: #e21f3d;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  line-height: 35px;
}
.ms-drop.bottom {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  border: 1px solid #E5E5E5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.ms-drop.bottom ul { max-height: 250px; overflow: hidden; overflow-y: scroll; margin: 0px; padding-left: 10px; }

button.ms-choice {display: none;}

.filter-box h3 {font-size: 18px; margin: 0px 0px 15px 0px;}

#map-canvas img {
  max-width: none;
}
.gm-style img {
  max-width: none;
}
.gm-style label {
  width: auto;
  display: inline;
}

/*SUBMENU*/

.current_page_item {}
.page_item_has_children .children {
  display: none;
  /*text-indent: 10px;*/
}
.page_item_has_children .children .children {
  text-indent: 20px;
  display: none;
}
.page_item_has_children .children .children .children {
  text-indent: 30px;
  display: none;
}
.page_item_has_children .children .children .children .children {
  text-indent: 40px;
  display: none;
}
.page_item_has_children .children .children .children .children .children {
  text-indent: 50px;
  display: none;
}
.current_page_item .children {
  display: block;
}
.current_page_item .children li a {
  padding-left: 30px !important;
}
.mobile_submenu {
  display: none;
}
.submenu_toggle {
  cursor: pointer;
  background: url("/Assets/Images/down.svg");
  background-size: 25px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 0px;
  border-left: 2px solid #e5e5e5;
}
.mobile_title {
  background: #F2F2F2;
  position: relative;
  border: none;
}
.mobile_submenu li {
  line-height: 24px !important;
}

/*breadcrumbs*/
#breadcrumbs{background-color:#ffffff; border-bottom:1px solid #e5e5e5; width:calc(100% - 30px); margin-left: 15px; padding: 15px 15px 15px 15px; margin-bottom:0px;}
#breadcrumbs a{text-decoration:none;}
.page_item_has_children{background-image:url(/Assets/Images/arrowMen.svg); background-repeat:no-repeat; background-size:9px; background-position: right 10px top 8px;}

ul, ol, dl {font-size: 12px;}

.social-header {
    text-align: left;
    margin-top: 13px;
    white-space: nowrap;
}
.social-header a{margin-right:3px;}


/*FOUNDATION STYLES*/
.button{
	font-family:'open-sans', Arial, sans-serif;
    background: #e62245;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
    line-height: 35px;
    border-radius:2px;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    padding-top: 10px;
    padding-right: 35px;
    padding-bottom: 10px;
    padding-left: 35px;
}
.button:hover{
	background: #e24055;
}
.button.tiny {
    padding-top: 1px;
    padding-right: 15px;
    padding-bottom: 1px;
    padding-left: 15px;
    font-size: 0.6875rem;
}
.button.small {
    padding-top: 5px;
    padding-right: 25px;
    padding-bottom: 5px;
    padding-left: 25px;
    font-size: 0.8125rem;
}
.button.full {
    width: 100%;
}
.button.secondary {
    background-color: #2c3e50;
    color:#fff;
}
.button.secondary:hover {
    background-color: #384c64;
    color:#fff;
}

/* Social header */

.social-header img {max-width: 29px; height: 29px;}

#av-content #av-searchbox #av-search {width: 80%; float: left; height: 40px;}
#av-content #av-searchbox #av-searchbtn {border: 0; width: 20%; background: #e21f3d; text-align: center; color: #ffffff; font-weight: 700; line-height: 35px;  float: left;}

#av-content h3 {font-size: 16px; line-height: normal;}

#av-monthly .logo {
  width: 100px;
  float: left;
  margin-right: 20px; }
#av-monthly h2 {
font-size: 24px;
  margin-bottom: 20px;
margin-bottom: 20px;
}
#av-newsletter {
  clear: both; }
#av-newsletter #_av-email {
    width: calc(100% - 90px);
    float: left;
    border-radius: 3px 0px 0px 3px; }
#av-newsletter #av-submit {
    float: left;
    width: 90px;
    height: 42px;
    background: #086986;
    font-family: "ff-tisa-web-pro", serif;
    color: #ffffff;
    font-size: 16px;
    border: none;
    padding-top: 5px;
    text-align: center; }
#av-searchform .av-search {
  clear: both; }

 #av-searchform ul {
  list-style: none;
  margin: 0px; }
 #av-searchform ul li {
    width: 33.333%;
    float: left;
    padding-right: 10px; }
#av-searchform ul li:last-child {
    padding-right: 0px; }
#av-searchform ul li label {
    font-weight: bold; }
#av-offers {
  clear: both; }
#av-offers ul {
    margin: 0px;
    list-style: none; }
#av-offers ul li {
      position: relative;
      background: #F9F9F9;
      border: 1px solid #cacaca;
      border-radius: 3px;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 140px;
      position: relative; }
#av-offers ul li .logo {
        position: absolute;
        width: 100px;
        height: auto;
        left: 20px;
        top: 20px; }
#av-offers .av-pager a, .lo_mervarde_page #av-offers .av-pager em {
    display: inline-block;
    color: #A70D1A;
    border: 1px solid #e9e9e9;
    margin-right: 3px;
    margin-left: 3px;
    border-radius: 2px;
    padding: 0.1875rem 0.625rem; }
#av-offers .av-pager em {
    background: #066986;
    color: #ffffff;
    font-style: normal; }
#av-offer .logo {
  width: 100px;
  float: left;
  margin-right: 15px; }


/*New Fixes */

.simplefavorite-button {background: #fff url("/Assets/Images/favorite.svg") no-repeat center !important;
    background-size: 14px auto !important;
    border: 1px solid #E0E0E0 !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: transparent !important;
  width: 34px;
  height: 34px;
  padding: 0px;
  margin: 0px;
  transition: none !important;
  outline: none !important;
}
.simplefavorite-button:hover, .simplefavorite-button:focus {color: transparent; background-color: #ffffff; outline: none !important;}

.simplefavorite-button.active {opacity: 1; border: none !important; background: #f6a43f url("/Assets/Images/favorite_white.svg") no-repeat center !important; background-size: 14px auto !important; outline: none !important;}
.simplefavorite-button.active:hover, .simplefavorite-button.active:focus {color: transparent; background-color: #f6a43f; outline: none !important;}