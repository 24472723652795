﻿@import "../common/variables";

.List {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.List-item {
	margin-bottom: 10px;
}

.List--message {
	.List-item {
		border-top: 1px solid #ececec;
		padding: 10px;
		color: #000;
		font-size: 10px;
		font-weight: bold;
		margin: 0;
		display: flex;
	}

	a {
		display: block;
	}

	i {
		margin: 5px 10px 0 0;
	}
}


.List-inbox {
	.List-inboxTop {
		padding: 10px;

		.List-inbox--deleteAll {
			cursor: pointer;
		}

		label {
			display: inline-block;
			margin:0;

		}
	}

	input[type="checkbox"] {
		margin-bottom: 0 !important;
		margin-top: 10px;
		margin-right: 10px;
	}
}

.List--withLinks {
    .List-item {
        list-style: none;
        border-bottom: 1px dashed #ccc;
        padding: 10px 0;
        margin: 0;

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            border: none;
            padding-bottom: 0;
        }
    }

    a {
        text-decoration: none;
        color: @colorText;
        display: block;
        position: relative;
        font-size: unit(14 / @baseFontSize, rem);
        font-family: 'museo-slab', serif;
        font-weight: 600;
        overflow-wrap: break-word;
    }
}

.List--withArrow {
	a {
		padding-right: 30px;

		&:after {
			content: "";
			position: absolute;
			right: 0;
			width: 14px;
			height: 14px;
			background-image: url(/Assets/Images/arr-btn-down.svg);
			background-repeat: no-repeat;
			top: 50%;
			transform: rotate(-90deg) translate(50%, 0);
		}
	}
}