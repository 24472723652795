@import (reference) "../common/variables.less";

/**
* Add classes for common colors.
*/

.u-colorText {
    color: @colorText !important;
}

.u-colorTextLight {
    color: @colorTextLight !important;
}

.u-colorGray {
    color: @colorLinkHover !important;
}

.u-colorWhite {
    color: #fff !important;
}

// Examples Text Colors: 

// .u-colorWhite {
// 	color: #fff !important;
// }
// 
// .u-colorBlue {
// 	color: @colorBlue !important;
// }
// 
// .u-colorRed {
// 	color: @colorRed !important;
// }

// Example Background Colors: 

.u-colorBgWhite {
	background-color: #fff !important;
}